/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const BREAKPOINT_SM = `var(--${DESIGN_TOKENS_PREFIX}-breakpoint-sm)` as const
export const BREAKPOINT_SM_VALUE = '0' as const

/** `601px` */
export const BREAKPOINT_MD = `var(--${DESIGN_TOKENS_PREFIX}-breakpoint-md)` as const
export const BREAKPOINT_MD_VALUE = '601px' as const

/** `1025px` */
export const BREAKPOINT_LG = `var(--${DESIGN_TOKENS_PREFIX}-breakpoint-lg)` as const
export const BREAKPOINT_LG_VALUE = '1025px' as const

/** `1441px` */
export const BREAKPOINT_XL = `var(--${DESIGN_TOKENS_PREFIX}-breakpoint-xl)` as const
export const BREAKPOINT_XL_VALUE = '1441px' as const
