import { ChangeEvent, forwardRef, Ref, useCallback } from 'react'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { Arrow } from './components/Arrow'
import { SelectField } from './components/SelectField'
import { SelectWrapper } from './components/SelectWrapper'
import { Wrapper } from './components/Wrapper'
import { SelectProps } from './types'

/** @deprecated */
export const Select = forwardRef(
  (
    {
      children,
      defaultValue,
      disabled = false,
      errorMessage,
      helperText,
      hideError = false,
      id,
      invalid = false,
      label = '',
      name,
      onBlur,
      onChange,
      onFocus,
      required = false,
      textAlign = 'left',
      value,
    }: SelectProps,
    ref: Ref<HTMLSelectElement>,
  ) => {
    const inputId = id || name

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
          onChange(event.target.value)
        }
      },
      [onChange],
    )

    const hasError = errorMessage && invalid && !disabled

    return (
      <Wrapper>
        {label && (
          <Text align={textAlign} as="label" colorName={hasError ? 'coral10' : 'grey10'} htmlFor={name} kind="label">
            {label}
          </Text>
        )}

        <SelectWrapper>
          <SelectField
            ref={ref}
            defaultValue={defaultValue}
            disabled={disabled}
            id={inputId}
            invalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
            required={required}
            value={value}
          >
            {children}
          </SelectField>

          <Arrow />
        </SelectWrapper>

        {hasError && !hideError && (
          <Flex pt={8}>
            <Text colorName="coral10" kind="label">
              {errorMessage}
            </Text>
          </Flex>
        )}

        {helperText && (
          <Flex pt={8}>
            <Text colorName="grey10" kind="label">
              {helperText}
            </Text>
          </Flex>
        )}
      </Wrapper>
    )
  },
)
