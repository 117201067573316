/**
 * Asserts that the passed argument is of type `never`.
 * This function is useful to check the exhaustiveness of all cases, e.g., in a switch statement.
 *
 * Example:
 * ```ts
 * switch (name) {
 *   case 'a':
 *     return 'a'
 *   case 'b':
 *     return 'b'
 *   default:
 *     return assertNever(name)
 * }
 * ```
 *
 * Read this section for more info:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export const assertNever = (x: never): never => {
  throw new Error(`Unexpected object: ${x}`)
}
