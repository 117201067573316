import { css } from 'styled-components'
import { media } from './media'

/** @deprecated Use the `cdk` one */
export type VisibilityProps = {
  hide?: boolean
  lgHide?: boolean
  mdHide?: boolean
}

const getHide = (value?: boolean) => {
  if (value === undefined) {
    return ''
  }

  return `display: ${value ? 'none' : 'flex'};`
}

/** @deprecated Use the `cdk` one */
export const visibility = css<VisibilityProps>`
  ${({ hide }) => getHide(hide)}

  ${({ mdHide }) =>
    media.medium`
      ${getHide(mdHide)}
    `}

  ${({ lgHide }) =>
    media.large`
      ${getHide(lgHide)}
    `}
`
