import { assertNever, noop } from 'functions'
import { Ref, forwardRef, useMemo } from 'react'
import styled from 'styled-components'
import { ButtonProps } from '../types/button'
import { ButtonPropsByKind, buttonWrapperStyles, getButtonPropsByKind } from '../utils/button'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { Loader } from './Loader'
import { Text } from './Text'

const ButtonWrapper = styled.button.withConfig({
  displayName: 'ButtonWrapper',
  shouldForwardProp,
})<ButtonProps & { colorState: ButtonPropsByKind }>`
  margin: 0;
  outline: 0;
  font-family: inherit;
  cursor: pointer;
  appearance: none;

  > * {
    line-height: inherit;
  }

  ${buttonWrapperStyles}
`

/** @deprecated Use the `ui` one */
export const Button = forwardRef(
  (
    {
      as = 'button',
      children,
      disabled = false,
      form = undefined,
      id = undefined,
      kind,
      loading = false,
      onClick,
      role = 'button',
      size = 'medium',
      testId,
      type = 'button',
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const colorState = useMemo(() => getButtonPropsByKind({ kind }), [kind])

    const typographyKind = useMemo(() => {
      switch (size) {
        case 'medium':
          return 'paragraph'
        case 'small':
          return 'label'

        default:
          return assertNever(size)
      }
    }, [size])

    return (
      <ButtonWrapper
        ref={ref}
        as={as}
        colorState={colorState}
        data-test-id={testId}
        disabled={disabled || loading}
        kind={kind}
        onClick={onClick ?? noop}
        size={size}
        {...(form && { form })}
        {...(id && { id })}
        {...(as === 'a' && { role })}
        {...(as === 'button' && { type })}
      >
        {loading && <Loader colorName={colorState.loading} size={20} />}

        {!loading && (
          <Text align="center" fontWeight={700} kind={typographyKind}>
            {children}
          </Text>
        )}
      </ButtonWrapper>
    )
  },
)

Button.displayName = 'Button'
