import { ColorName } from '@serenis-health/tokens'
import { State } from '../types/useInput'

/** @deprecated */
export const getLabelColorName = (state: State): ColorName => {
  if (state === 'disabled') {
    return 'grey08'
  }

  if (state === 'read-only') {
    return 'grey08'
  }

  if (state === 'error') {
    return 'coral10'
  }

  return 'grey10'
}
