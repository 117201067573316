import { Spacing } from '@serenis-health/tokens'
import { assertNever } from 'functions'
import { css } from 'styled-components'
import { media } from './media'

/** @deprecated */
type Padding = {
  p: Spacing
  pb: Spacing
  pl: Spacing
  pr: Spacing
  pt: Spacing
  px: Spacing
  py: Spacing
}

type MdPadding = {
  mdP: Spacing
  mdPb: Spacing
  mdPl: Spacing
  mdPr: Spacing
  mdPt: Spacing
  mdPx: Spacing
  mdPy: Spacing
}

type LgPadding = {
  lgP: Spacing
  lgPb: Spacing
  lgPl: Spacing
  lgPr: Spacing
  lgPt: Spacing
  lgPx: Spacing
  lgPy: Spacing
}

/** @deprecated Use the `cdk` one */
export type PaddingProps = Partial<Padding & MdPadding & LgPadding>

const switchProp = (prop: keyof Padding, value: Padding[keyof Padding]) => {
  switch (prop) {
    case 'p':
      return `padding: ${value}px;`
    case 'pb':
      return `padding-bottom: ${value}px;`
    case 'pl':
      return `padding-left: ${value}px;`
    case 'pr':
      return `padding-right: ${value}px;`
    case 'pt':
      return `padding-top: ${value}px;`
    case 'px':
      return `
        padding-left: ${value}px;
        padding-right: ${value}px;
      `
    case 'py':
      return `
        padding-bottom: ${value}px;
        padding-top: ${value}px;
      `
    default:
      return assertNever(prop)
  }
}

const getPropStyle = <P extends keyof Padding>(prop: P, value?: Padding[P]) => {
  if (value === undefined) {
    return ''
  }

  // NOTE: The reason why we call a separate function instead of directly having the switch
  // statement is that the type of `prop` is not narrowed when using `P extends Keys`.
  return switchProp(prop, value)
}

/** @deprecated Use the `cdk` one */
export const padding = css<PaddingProps>`
  ${({ p, pb, pl, pr, pt, px, py }) => `
    ${getPropStyle('p', p)}
    ${getPropStyle('px', px)}
    ${getPropStyle('py', py)}
    ${getPropStyle('pb', pb)}
    ${getPropStyle('pl', pl)}
    ${getPropStyle('pr', pr)}
    ${getPropStyle('pt', pt)}
  `}

  ${({ mdP, mdPb, mdPl, mdPr, mdPt, mdPx, mdPy }) =>
    media.medium`
      ${getPropStyle('p', mdP)}
      ${getPropStyle('px', mdPx)}
      ${getPropStyle('py', mdPy)}
      ${getPropStyle('pb', mdPb)}
      ${getPropStyle('pl', mdPl)}
      ${getPropStyle('pr', mdPr)}
      ${getPropStyle('pt', mdPt)}
    `}

  ${({ lgP, lgPb, lgPl, lgPr, lgPt, lgPx, lgPy }) =>
    media.large`
      ${getPropStyle('p', lgP)}
      ${getPropStyle('px', lgPx)}
      ${getPropStyle('py', lgPy)}
      ${getPropStyle('pb', lgPb)}
      ${getPropStyle('pl', lgPl)}
      ${getPropStyle('pr', lgPr)}
      ${getPropStyle('pt', lgPt)}
    `}
`
