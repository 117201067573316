import { ColorName, cssvarColor } from 'design-tokens'
import { css, CSSProperties } from 'styled-components'
import { StyledProps } from '../utils/props'
import { toValueOrPX } from '../utils/toValueOrPX'

type BaseBorderProps = {
  borderSize?: CSSProperties['borderWidth']
  borderSizeTop?: CSSProperties['borderTopWidth']
  borderSizeRight?: CSSProperties['borderRightWidth']
  borderSizeBottom?: CSSProperties['borderBottomWidth']
  borderSizeLeft?: CSSProperties['borderLeftWidth']
  borderStyle?: CSSProperties['borderTopStyle'] &
    CSSProperties['borderRightStyle'] &
    CSSProperties['borderBottomStyle'] &
    CSSProperties['borderLeftStyle']
  borderStyleTop?: CSSProperties['borderTopStyle']
  borderStyleRight?: CSSProperties['borderRightStyle']
  borderStyleBottom?: CSSProperties['borderBottomStyle']
  borderStyleLeft?: CSSProperties['borderLeftStyle']
  borderColorName?: ColorName
  borderColorNameTop?: ColorName
  borderColorNameRight?: ColorName
  borderColorNameBottom?: ColorName
  borderColorNameLeft?: ColorName
  borderRadius?: CSSProperties['borderRadius']
  borderRadiusTopLeft?: CSSProperties['borderTopLeftRadius']
  borderRadiusTopRight?: CSSProperties['borderTopRightRadius']
  borderRadiusBottomRight?: CSSProperties['borderBottomRightRadius']
  borderRadiusBottomLeft?: CSSProperties['borderBottomLeftRadius']
}

export type BorderProps = StyledProps<BaseBorderProps>

export const generateBorderStyles = ({
  borderSize,
  borderSizeTop,
  borderSizeRight,
  borderSizeBottom,
  borderSizeLeft,
  borderStyle,
  borderStyleTop,
  borderStyleRight,
  borderStyleBottom,
  borderStyleLeft,
  borderColorName,
  borderColorNameTop,
  borderColorNameRight,
  borderColorNameBottom,
  borderColorNameLeft,
  borderRadius,
  borderRadiusTopLeft,
  borderRadiusTopRight,
  borderRadiusBottomRight,
  borderRadiusBottomLeft,
}: BaseBorderProps) => css`
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  border-width: ${toValueOrPX(borderSize)};
  border-style: ${borderStyle};
  border-color: ${borderColorName && cssvarColor(borderColorName)};
  border-top-width: ${toValueOrPX(borderSizeTop)};
  border-top-style: ${borderStyleTop};
  border-top-color: ${borderColorNameTop && cssvarColor(borderColorNameTop)};
  border-right-width: ${toValueOrPX(borderSizeRight)};
  border-right-style: ${borderStyleRight};
  border-right-color: ${borderColorNameRight && cssvarColor(borderColorNameRight)};
  border-bottom-width: ${toValueOrPX(borderSizeBottom)};
  border-bottom-style: ${borderStyleBottom};
  border-bottom-color: ${borderColorNameBottom && cssvarColor(borderColorNameBottom)};
  border-left-width: ${toValueOrPX(borderSizeLeft)};
  border-left-style: ${borderStyleLeft};
  border-left-color: ${borderColorNameLeft && cssvarColor(borderColorNameLeft)};
  border-radius: ${toValueOrPX(borderRadius)};
  border-top-left-radius: ${toValueOrPX(borderRadiusTopLeft)};
  border-top-right-radius: ${toValueOrPX(borderRadiusTopRight)};
  border-bottom-right-radius: ${toValueOrPX(borderRadiusBottomRight)};
  border-bottom-left-radius: ${toValueOrPX(borderRadiusBottomLeft)};
`
