import { Flex, MaxWidth1260px } from 'cdk'
import { SerenisLogoHorizontal } from 'illustrations'
import { Link } from 'react-router-dom'
import { getRoute } from '~/utils/getRoute'
import { APP_BAR_HEIGHT } from '../constants'

export const ErrorAppBar = () => (
  <Flex $backgroundColorName="lighter" $basis={APP_BAR_HEIGHT} $justify="center" $px={16} as="header">
    <MaxWidth1260px $align="flex-start">
      <Link aria-label="Serenis Health" to={getRoute('/')}>
        <SerenisLogoHorizontal height={28} primaryColorName="primary" secondaryColorName="darker" />
      </Link>
    </MaxWidth1260px>
  </Flex>
)
