import { noop } from 'functions'
import { ChangeEventHandler, forwardRef, ReactNode, Ref } from 'react'
import { Checkbox } from './Checkbox'
import { Flex } from './Flex'
import { Text } from './Text'

type Props = {
  checked?: boolean
  disabled?: boolean
  errorMessage?: string
  helperText?: string
  id?: string
  invalid?: boolean
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  required?: boolean
  type?: 'checkbox' | 'radio'
  value?: string
} & (
  | {
      label?: string
      children?: never
    }
  | {
      label?: never
      children?: ReactNode
    }
)

/** @deprecated */
export const CheckboxField = forwardRef(
  (
    {
      checked = false,
      children,
      disabled = false,
      errorMessage = '',
      helperText = '',
      id,
      invalid = false,
      label,
      name,
      onChange = noop,
      required = false,
      type = 'checkbox',
      value = '',
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => (
    <Flex>
      <Flex direction="row">
        <Checkbox
          ref={ref}
          checked={checked}
          disabled={disabled}
          id={id}
          invalid={invalid}
          name={name}
          onChange={onChange}
          required={required}
          type={type}
          value={value}
        />

        {label && (
          <Flex as="label" htmlFor={name} pl={16} pt={4} shrink={1}>
            <Text colorName={invalid ? 'coral08' : 'grey10'} kind="label">
              {label}
            </Text>
          </Flex>
        )}

        {children && (
          <Flex grow={1} pl={16} shrink={1}>
            {children}
          </Flex>
        )}
      </Flex>

      {errorMessage && invalid && (
        <Flex pt={8}>
          <Text colorName="coral08" kind="label">
            {errorMessage}
          </Text>
        </Flex>
      )}

      {helperText && (
        <Flex pt={8}>
          <Text colorName="grey10" kind="label">
            {helperText}
          </Text>
        </Flex>
      )}
    </Flex>
  ),
)
