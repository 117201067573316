/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { MediaQueryName } from '../types/media-query'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_MEDIA_QUERY_PREFIX = 'media-query'

export const cssvarNameMediaQuery = (mediaQuery: MediaQueryName) =>
  cssvarName(`${DESIGN_TOKENS_MEDIA_QUERY_PREFIX}-${mediaQuery}`)

export const cssvarValueMediaQuery = (mediaQuery: MediaQueryName) =>
  cssvarValue(`${DESIGN_TOKENS_MEDIA_QUERY_PREFIX}-${mediaQuery}`)

export const cssvarMediaQuery = (mediaQuery: MediaQueryName) =>
  cssvar(`${DESIGN_TOKENS_MEDIA_QUERY_PREFIX}-${mediaQuery}`)
