import { css, CSSProperties } from 'styled-components'
import { ResponsiveProps, StyledProps } from '../utils/props'
import { toValueOrPX } from '../utils/toValueOrPX'

type BaseFlexboxProps = {
  align?: CSSProperties['alignItems']
  basis?: CSSProperties['flexBasis']
  direction?: CSSProperties['flexDirection']
  gap?: CSSProperties['gap']
  grow?: CSSProperties['flexGrow']
  justify?: CSSProperties['justifyContent']
  shrink?: CSSProperties['flexShrink']
  wrap?: CSSProperties['flexWrap']
}

type MdFlexboxProps = ResponsiveProps<BaseFlexboxProps, 'md'>
type LgFlexboxProps = ResponsiveProps<BaseFlexboxProps, 'lg'>
type XlFlexboxProps = ResponsiveProps<BaseFlexboxProps, 'xl'>

export type FlexboxProps = StyledProps<BaseFlexboxProps & MdFlexboxProps & LgFlexboxProps & XlFlexboxProps>

export const generateFlexboxStyles = ({
  align,
  basis,
  direction,
  gap,
  grow,
  justify,
  shrink,
  wrap,
}: BaseFlexboxProps) => css`
  flex-grow: ${grow};
  flex-shrink: ${shrink};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-basis: ${toValueOrPX(basis)};
  flex-direction: ${direction};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-wrap: ${wrap};
  gap: ${toValueOrPX(gap)};
  justify-content: ${justify};
  align-items: ${align};
`
