export const DESIGN_TOKENS_PREFIX = 'srns'

export const cssvarName = <T extends string>(variable: T): `--${typeof DESIGN_TOKENS_PREFIX}-${typeof variable}` =>
  `--${DESIGN_TOKENS_PREFIX}-${variable}`

export const cssvarValue = <T extends string>(variable: T) => {
  if (typeof window === 'undefined') {
    return ''
  }

  return getComputedStyle(document.body).getPropertyValue(cssvarName(variable))
}

export const cssvar = <T extends string>(variable: T): `var(${ReturnType<typeof cssvarName<T>>})` =>
  `var(${cssvarName(variable)})`
