import { COLOR_WHITE } from 'design-tokens'
import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathNutritionWeightLossSolid = ({ width }: Props) => (
  <svg
    data-test-id="icon-path-nutrition-weight-loss-solid"
    fill="none"
    height={width}
    viewBox="0 0 48 48"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C4.8 0 0 4.805 0 24.023S4.8 48 24 48s24-4.804 24-24.023C48 4.76 43.2 0 24 0Z"
      fill="url(#icon-path-nutrition-weight-loss-solid-a)"
    />
    <circle cx="24" cy="27" r="10.8" stroke={COLOR_WHITE} strokeWidth="2.4" />
    <circle cx="24" cy="17" r="6.8" stroke={COLOR_WHITE} strokeWidth="2.4" />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="icon-path-nutrition-weight-loss-solid-a"
        x1="0"
        x2="48"
        y1="0"
        y2="48"
      >
        <stop stopColor="#179A92" />
        <stop offset="1" stopColor="#34C9C0" />
      </linearGradient>
    </defs>
  </svg>
)
