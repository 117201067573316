import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { getBorderColorName } from './utils/getBorderColorName'
import { getInputColorName } from './utils/getInputColorName'
import { getLabelColorName } from './utils/getLabelColorName'
import { getStateFromProps } from './utils/getStateFromProps'
import { getUnderlineColorName } from './utils/getUnderlineColorName'

type Param = {
  disabled: boolean
  invalid: boolean
  onBlur: () => void
  onChange: (value: string) => void
  onFocus: () => void
  readOnly: boolean
  value?: string
  warning: boolean
}

/** @deprecated */
export const useInput = ({ disabled, invalid, onBlur, onChange, onFocus, readOnly, value, warning }: Param) => {
  const [focused, setFocused] = useState(false)

  const state = useMemo(
    () => getStateFromProps({ disabled, invalid, readOnly, warning }),
    [disabled, invalid, readOnly, warning],
  )

  const inputColorName = useMemo(() => getInputColorName(state), [state])

  const borderColorName = useMemo(() => getBorderColorName({ focused, state, value }), [focused, state, value])

  const labelColorName = useMemo(() => getLabelColorName(state), [state])

  const underlineColorName = useMemo(() => getUnderlineColorName({ focused, state }), [focused, state])

  const handleBlur = useCallback(() => {
    setFocused(false)

    onBlur()
  }, [onBlur, setFocused])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange],
  )

  const handleFocus = useCallback(() => {
    setFocused(true)

    onFocus()
  }, [onFocus, setFocused])

  return {
    error: state === 'error',
    handleBlur,
    handleChange,
    handleFocus,
    inputColorName,
    borderColorName,
    labelColorName,
    underlineColorName,
  }
}
