import { ReactNode } from 'react'
import styled from 'styled-components'
import { background, BackgroundProps } from '../utils/background'
import { border, BorderProps } from '../utils/border'
import { flexbox, Flexbox, FlexboxProps } from '../utils/flexbox'
import { padding, PaddingProps } from '../utils/padding'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { visibility, VisibilityProps } from '../utils/visibility'
import { View } from './View'

type StyleProps = BackgroundProps & BorderProps & FlexboxProps & PaddingProps & VisibilityProps

const defaultProps: Flexbox = {
  align: 'stretch',
  basis: 'auto',
  direction: 'column',
  grow: 0,
  justify: 'flex-start',
  shrink: 0,
  wrap: 'nowrap',
}

/** @deprecated Use the `cdk` one */
export type FlexProps = StyleProps & {
  children?: ReactNode
}

/** @deprecated Use the `cdk` one */
export const Flex = styled(View)
  .withConfig({
    displayName: 'Flex',
    shouldForwardProp,
  })
  .attrs((props: FlexProps) => ({
    ...defaultProps,
    ...props,
  }))<FlexProps>`
  ${background}
  ${border}
  ${flexbox}
  ${padding}
  ${visibility}
`
