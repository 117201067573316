import { TIME_300 } from 'design-tokens'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

export type LabelProps = PropsWithChildren<{
  /** Use `id` for `aria-labelledby` together with Form Fields @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  id?: string
}>

/**
 * Should be used with Form Fields with `id` for `aria-labelledby`
 */
export const Label = styled(Text)
  .withConfig({ displayName: 'Label' })
  .attrs(({ colorName = 'primary' }) => ({
    colorName,
    as: 'label',
    fontWeight: '500',
    kind: 'caption',
  }))<LabelProps>`
  transition: color ${TIME_300} ease-out;
`
