import { State } from '../types/useInput'

type Param = {
  disabled: boolean
  invalid: boolean
  readOnly: boolean
  warning: boolean
}

/** @deprecated */
export const getStateFromProps = ({ disabled, invalid, readOnly, warning }: Param): State => {
  if (disabled) {
    return 'disabled'
  }

  if (readOnly) {
    return 'read-only'
  }

  if (invalid) {
    return 'error'
  }

  if (warning) {
    return 'warning'
  }

  return 'default'
}
