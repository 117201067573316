import {
  BreakpointName,
  cssvarValueBreakpoint,
  MEDIA_QUERY_GT_LG_VALUE,
  MEDIA_QUERY_GT_MD_VALUE,
  MEDIA_QUERY_GT_SM_VALUE,
  MEDIA_QUERY_LG_VALUE,
  MEDIA_QUERY_LT_LG_VALUE,
  MEDIA_QUERY_LT_MD_VALUE,
  MEDIA_QUERY_LT_XL_VALUE,
  MEDIA_QUERY_MD_VALUE,
  MEDIA_QUERY_SM_VALUE,
  MEDIA_QUERY_XL_VALUE,
} from 'design-tokens'
import { css, Interpolation, RuleSet } from 'styled-components'

type CssMediaQueryName =
  | BreakpointName
  | `gt${Capitalize<Exclude<BreakpointName, 'xl'>>}`
  | `lt${Capitalize<Exclude<BreakpointName, 'sm'>>}`

const cssWithMediaQuery =
  (breakpoint: ReturnType<typeof cssvarValueBreakpoint>) =>
  (first: TemplateStringsArray, ...interpolations: Interpolation<object>[]): RuleSet<object> => {
    const styles = css(first, ...interpolations)

    if (!styles.join('').trim()) {
      return ['']
    }

    return css`
      /* stylelint-disable-next-line media-query-no-invalid */
      @media ${breakpoint} {
        ${styles}
      }
    `
  }

export const media: Record<CssMediaQueryName, ReturnType<typeof cssWithMediaQuery>> = {
  /** \@media (max-width: 600px) { ... } */
  sm: cssWithMediaQuery(MEDIA_QUERY_SM_VALUE),

  /** \@media (min-width: 601px) and (max-width: 1024px) { ... } */
  md: cssWithMediaQuery(MEDIA_QUERY_MD_VALUE),

  /** \@media (min-width: 1025px) and (max-width: 1440px) { ... } */
  lg: cssWithMediaQuery(MEDIA_QUERY_LG_VALUE),

  /** \@media (min-width: 1441px) { ... } */
  xl: cssWithMediaQuery(MEDIA_QUERY_XL_VALUE),

  /** \@media (min-width: 601px) { ... } */
  gtSm: cssWithMediaQuery(MEDIA_QUERY_GT_SM_VALUE),

  /** \@media (min-width: 1025px) { ... } */
  gtMd: cssWithMediaQuery(MEDIA_QUERY_GT_MD_VALUE),

  /** \@media (min-width: 1441px) { ... } */
  gtLg: cssWithMediaQuery(MEDIA_QUERY_GT_LG_VALUE),

  /** \@media (max-width: 600px) { ... } */
  ltMd: cssWithMediaQuery(MEDIA_QUERY_LT_MD_VALUE),

  /** \@media (max-width: 1024px) { ... } */
  ltLg: cssWithMediaQuery(MEDIA_QUERY_LT_LG_VALUE),

  /** \@media (max-width: 1440px) { ... } */
  ltXl: cssWithMediaQuery(MEDIA_QUERY_LT_XL_VALUE),
}
