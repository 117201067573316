import { ColorName, colors, fontFamily, TextAlign, toColorName } from '@serenis-health/tokens'
import styled from 'styled-components'
import { shouldForwardProp } from '../../../utils/shouldForwardProp'

type Props = {
  colorName: ColorName
  disabled: boolean
  textAlign: TextAlign
}

const defaultProps: Props = {
  colorName: 'black',
  disabled: false,
  textAlign: 'left',
}

/** @deprecated */
export const Input = styled.input
  .withConfig({
    displayName: 'Input',
    shouldForwardProp,
  })
  .attrs((props) => ({ ...defaultProps, ...props }))<Partial<Props>>`
  appearance: none;
  -webkit-text-fill-color: ${({ colorName }) => toColorName(colorName)};
  background: transparent;
  border: none;
  color: ${({ colorName }) => toColorName(colorName)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: 400;
  height: 46px;
  line-height: 140%;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 12px 0;
  text-align: ${({ textAlign }) => textAlign};

  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    box-shadow: inset 0 0 0 1000px ${colors.white};
  }

  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    -webkit-text-fill-color: ${colors.grey08};
    color: ${colors.grey08};
  }

  &[type='number'] {
    appearance: textfield;
    -webkit-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
    }
  }
`
