export const specialToNormalChars: Record<string, string> = {
  à: 'a',
  á: 'a',
  â: 'a',
  ä: 'a',
  æ: 'a',
  ã: 'a',
  å: 'a',
  ā: 'a',
  ă: 'a',
  ą: 'a',
  ç: 'c',
  ć: 'c',
  č: 'c',
  đ: 'd',
  ď: 'd',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  ē: 'e',
  ė: 'e',
  ę: 'e',
  ě: 'e',
  ğ: 'g',
  ǵ: 'g',
  ḧ: 'h',
  î: 'i',
  ï: 'i',
  í: 'i',
  ī: 'i',
  į: 'i',
  ì: 'i',
  ł: 'l',
  ḿ: 'm',
  ñ: 'n',
  ń: 'n',
  ǹ: 'n',
  ň: 'n',
  ô: 'o',
  ö: 'o',
  ò: 'o',
  ó: 'o',
  œ: 'o',
  ø: 'o',
  ō: 'o',
  õ: 'o',
  ő: 'o',
  ṕ: 'p',
  ŕ: 'r',
  ř: 'r',
  ß: 's',
  ś: 's',
  š: 's',
  ş: 's',
  ș: 's',
  ť: 't',
  ț: 't',
  û: 'u',
  ü: 'u',
  ù: 'u',
  ú: 'u',
  ū: 'u',
  ǘ: 'u',
  ů: 'u',
  ű: 'u',
  ų: 'u',
  ẃ: 'w',
  ẍ: 'x',
  ÿ: 'y',
  ý: 'y',
  ž: 'z',
  ź: 'z',
  ż: 'z',
  '·': '-',
  '/': '-',
  _: '-',
  ',': '-',
  ':': '-',
  ';': '-',
}

export const specialCharsRegex = new RegExp(Object.keys(specialToNormalChars).join('|'), 'g')
