import { isSameMonth } from 'date-fns/fp'
import { format, FormatDateEnum, FormatDateLanguage } from 'dates'
import { pipe } from 'fp-ts/function'
import { ArrowLeftCircle, ArrowRightCircle } from 'iconoir-react'
import { Flex } from '../../Flex'
import { Pressable } from '../../Pressable'
import { Text } from '../../Text'
import { useCalendar } from '../hooks/useCalendar'

type Props = {
  language: FormatDateLanguage
  onNext: () => void
  onPrevious: () => void
}

/** @deprecated */
export const CalendarNavigation = ({ language, onNext, onPrevious }: Props) => {
  const { date, disabledBeforeDate } = useCalendar()

  return (
    <Flex align="center" direction="row" grow={1} pb={8} shrink={1}>
      <Flex align="center" basis="14.28%">
        {!isSameMonth(disabledBeforeDate, date) && (
          <Pressable autoWidth colorName="purple08" colorNameHover="purple06" onClick={onPrevious}>
            <ArrowLeftCircle height={20} width={20} />
          </Pressable>
        )}
      </Flex>

      <Flex align="center" grow={1} px={16}>
        <Text kind="paragraph">{pipe(date, format(FormatDateEnum.MONTH_NAME, language))}</Text>
      </Flex>

      <Flex align="center" basis="14.28%">
        <Pressable autoWidth colorName="purple08" colorNameHover="purple06" onClick={onNext}>
          <ArrowRightCircle height={20} width={20} />
        </Pressable>
      </Flex>
    </Flex>
  )
}
