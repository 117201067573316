/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { LetterSpacingName } from '../types/letter-spacing'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_LETTER_SPACING_PREFIX = 'letter-spacing'

export const cssvarNameLetterSpacing = (letterSpacing: LetterSpacingName) =>
  cssvarName(`${DESIGN_TOKENS_LETTER_SPACING_PREFIX}-${letterSpacing}`)

export const cssvarValueLetterSpacing = (letterSpacing: LetterSpacingName) =>
  cssvarValue(`${DESIGN_TOKENS_LETTER_SPACING_PREFIX}-${letterSpacing}`)

export const cssvarLetterSpacing = (letterSpacing: LetterSpacingName) =>
  cssvar(`${DESIGN_TOKENS_LETTER_SPACING_PREFIX}-${letterSpacing}`)
