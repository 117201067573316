/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const BORDER_RADIUS_0 = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-0)` as const
export const BORDER_RADIUS_0_VALUE = '0' as const

/** `2px` */
export const BORDER_RADIUS_4XS = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-4xs)` as const
export const BORDER_RADIUS_4XS_VALUE = '2px' as const

/** `4px` */
export const BORDER_RADIUS_3XS = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-3xs)` as const
export const BORDER_RADIUS_3XS_VALUE = '4px' as const

/** `6px` */
export const BORDER_RADIUS_2XS = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-2xs)` as const
export const BORDER_RADIUS_2XS_VALUE = '6px' as const

/** `8px` */
export const BORDER_RADIUS_XS = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-xs)` as const
export const BORDER_RADIUS_XS_VALUE = '8px' as const

/** `12px` */
export const BORDER_RADIUS_SM = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-sm)` as const
export const BORDER_RADIUS_SM_VALUE = '12px' as const

/** `16px` */
export const BORDER_RADIUS_MD = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-md)` as const
export const BORDER_RADIUS_MD_VALUE = '16px' as const

/** `24px` */
export const BORDER_RADIUS_LG = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-lg)` as const
export const BORDER_RADIUS_LG_VALUE = '24px' as const

/** `32px` */
export const BORDER_RADIUS_XL = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-xl)` as const
export const BORDER_RADIUS_XL_VALUE = '32px' as const

/** `40px` */
export const BORDER_RADIUS_2XL = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-2xl)` as const
export const BORDER_RADIUS_2XL_VALUE = '40px' as const

/** `48px` */
export const BORDER_RADIUS_3XL = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-3xl)` as const
export const BORDER_RADIUS_3XL_VALUE = '48px' as const

/** `9999px` */
export const BORDER_RADIUS_CIRCLE = `var(--${DESIGN_TOKENS_PREFIX}-border-radius-circle)` as const
export const BORDER_RADIUS_CIRCLE_VALUE = '9999px' as const
