import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisJournalingSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M1.893 22.303h11.495a5.777 5.777 0 0 0 3.546-1.216l.361-.281m0 0 1.534-1.193a2.262 2.262 0 0 0-1.39-4.048h-.288a2.263 2.263 0 0 0-1.389 4.048l1.534 1.193Zm0 0 1.144.889c.507.394 1.13.608 1.773.608h1.897"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.04 19.08a1.725 1.725 0 0 0-.026-.068c-.24-.605-.868-1.84-2.373-3.345-1.506-1.505-2.74-2.133-3.345-2.374a1.754 1.754 0 0 0-.069-.025c-.14.22-.229.47-.252.733-.07.783-.146 2.3.006 4.46a.934.934 0 0 0 .865.866c2.16.152 3.677.076 4.46.006.265-.024.513-.113.734-.253Z"
        fill={color}
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        clipRule="evenodd"
        d="M16.733 1.338c-1.283-.929-2.898-.552-3.901.45l-1.05.995.13.068c.942.506 2.23 1.372 3.688 2.831 1.46 1.46 2.326 2.747 2.832 3.69.024.043.046.086.068.128l1.049-.994c1.003-1.003 1.38-2.618.451-3.902a14.582 14.582 0 0 0-1.507-1.76 14.584 14.584 0 0 0-1.76-1.506ZM3.338 11.283l7.374-7.375c.167.073.366.167.593.29.798.428 1.952 1.196 3.296 2.539 1.343 1.343 2.111 2.498 2.54 3.296.121.227.216.426.289.592L10.055 18a12.35 12.35 0 0 0-.068-.128c-.506-.943-1.373-2.23-2.832-3.69-1.459-1.459-2.746-2.325-3.689-2.831a13.541 13.541 0 0 0-.128-.068Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
)
