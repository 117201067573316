import { specialCharsRegex, specialToNormalChars } from './constants/specialChars'

/**
 * Slugifies the given string.
 *
 * @param string  Text to slugify
 */
export const slugify = (text: string): string =>
  text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(specialCharsRegex, (value) => specialToNormalChars[value])
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
