/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `12px` */
export const LINE_HEIGHT_12 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-12)` as const
export const LINE_HEIGHT_12_VALUE = '12px' as const

/** `16px` */
export const LINE_HEIGHT_16 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-16)` as const
export const LINE_HEIGHT_16_VALUE = '16px' as const

/** `20px` */
export const LINE_HEIGHT_20 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-20)` as const
export const LINE_HEIGHT_20_VALUE = '20px' as const

/** `24px` */
export const LINE_HEIGHT_24 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-24)` as const
export const LINE_HEIGHT_24_VALUE = '24px' as const

/** `32px` */
export const LINE_HEIGHT_32 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-32)` as const
export const LINE_HEIGHT_32_VALUE = '32px' as const

/** `40px` */
export const LINE_HEIGHT_40 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-40)` as const
export const LINE_HEIGHT_40_VALUE = '40px' as const

/** `48px` */
export const LINE_HEIGHT_48 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-48)` as const
export const LINE_HEIGHT_48_VALUE = '48px' as const

/** `56px` */
export const LINE_HEIGHT_56 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-56)` as const
export const LINE_HEIGHT_56_VALUE = '56px' as const

/** `64px` */
export const LINE_HEIGHT_64 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-64)` as const
export const LINE_HEIGHT_64_VALUE = '64px' as const

/** `72px` */
export const LINE_HEIGHT_72 = `var(--${DESIGN_TOKENS_PREFIX}-line-height-72)` as const
export const LINE_HEIGHT_72_VALUE = '72px' as const
