import { noop } from 'functions'
import { Check } from 'iconoir-react'
import { ChangeEvent, forwardRef, Ref } from 'react'
import { PositionRelative } from '../PositionRelative'
import { HiddenInput } from './components/HiddenInput'
import { StyledChoice } from './components/StyledChoice'
import { StyledChoiceDot } from './components/StyledChoiceDot'

type Props = {
  checked?: boolean
  disabled?: boolean
  id?: string
  invalid?: boolean
  name: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  type?: 'checkbox' | 'radio'
  value?: string
}

/** @deprecated Use the `ui` one */
export const Checkbox = forwardRef(
  (
    {
      checked = false,
      disabled = false,
      id,
      invalid = false,
      name,
      onChange = noop,
      required = false,
      type = 'checkbox',
      value = '',
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => (
    <PositionRelative>
      <HiddenInput
        ref={ref}
        checked={checked}
        disabled={disabled}
        id={type === 'checkbox' ? id || name : id}
        name={name}
        onChange={onChange}
        required={required}
        type={type}
        value={value}
      />

      <StyledChoice checked={checked} disabled={disabled} invalid={invalid} type={type}>
        <StyledChoiceDot checked={checked} disabled={disabled} invalid={invalid} type={type}>
          {type === 'checkbox' && checked && <Check color="white" height={24} width={24} />}
        </StyledChoiceDot>
      </StyledChoice>
    </PositionRelative>
  ),
)
