import { colors } from '@serenis-health/tokens'
import { TextAreaStyleProps } from '../../types'

/** @deprecated */
export const getBorderColorFromProps =
  (focused: boolean) =>
  ({ disabled, invalid }: Omit<TextAreaStyleProps, 'minHeight'>) => {
    if (disabled) {
      return colors.grey08
    }

    if (invalid) {
      return colors.coral10
    }

    if (focused) {
      return colors.purple08
    }

    return colors.purple06
  }
