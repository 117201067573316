import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathSexology = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-sexology"
    fill="none"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 39.87v-5.45m0 0 6.36-6.37M20 34.42l-6.36-6.37M10 24.68l-5.52-5.52A9.1 9.1 0 1 1 17.33 6.3L20 8.96l2.66-2.66a9.1 9.1 0 0 1 12.86 12.86l-5.53 5.52"
      stroke={color}
      strokeWidth="2.86"
    />
  </svg>
)
