import { Flex } from 'cdk'
import {
  BORDER_RADIUS_CIRCLE,
  BORDER_WIDTH_1,
  BORDER_WIDTH_4,
  COLOR_ERROR,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_50,
  COLOR_RED_20,
  TIME_300,
} from 'design-tokens'
import { forwardRef, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

const Input = styled.input.withConfig({ displayName: 'RadioInput' })`
  all: unset;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_50};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_LIGHTER};
  outline: revert;
  outline-offset: revert;
  cursor: pointer;
  transition: background-color ${TIME_300} ease-out;

  &:hover {
    background-color: ${COLOR_PRIMARY_10};
    color: ${COLOR_PRIMARY_10};
  }

  &:user-invalid {
    border-color: ${COLOR_ERROR};
  }

  &:disabled {
    border-color: ${COLOR_NEUTRAL_40};
    background-color: ${COLOR_NEUTRAL_20};
    cursor: not-allowed;
  }

  &:checked {
    border-width: ${BORDER_WIDTH_4};
    background-color: ${COLOR_LIGHTER};

    &:disabled {
      background-color: ${COLOR_NEUTRAL_40};
    }

    &:user-invalid {
      background-color: ${COLOR_ERROR};
    }
  }

  &:user-invalid:not(:checked):hover {
    background-color: ${COLOR_RED_20};
  }
`

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  name?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <Flex>
    <Input ref={ref} aria-checked={props.checked} aria-disabled={props.disabled} type="radio" {...props} />
  </Flex>
))
