import { ColorName } from '@serenis-health/tokens'
import { range } from 'arrays'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { shouldForwardProp } from '../../../utils/shouldForwardProp'
import { Flex } from '../../Flex'
import { Pressable } from '../../Pressable'
import { Text } from '../../Text'
import { Day } from '../types'
import { CalendarCell } from './CalendarCell'

type Props = {
  day: Day
  onSelect?: (day: Day) => void
}

const Dot = styled(Flex).withConfig({ shouldForwardProp })<{ visible: boolean }>`
  width: 4px;
  height: 4px;
  margin: -3px 1px 0;
  background-color: currentcolor;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

/** @deprecated */
export const CalendarDay = ({ day, onSelect }: Props) => {
  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(day)
    }
  }, [day, onSelect])

  const colorName = useMemo((): ColorName => {
    if (!day.belongsToCurrentMonth) {
      return 'black'
    }

    if (day.selected) {
      return 'white'
    }

    if (day.today) {
      return 'black'
    }

    if (day.disabled) {
      return 'grey06'
    }

    return 'black'
  }, [day])

  return (
    <CalendarCell day={day}>
      <Pressable colorName={colorName} disabled={day.disabled} onClick={onClick}>
        <Flex align="center">
          <Text kind="paragraph">{day.belongsToCurrentMonth ? day.date.getDate() : ''}</Text>

          <Flex align="center" basis="4px" direction="row" justify="center">
            {range(Math.min(day.hasEvent, 3), (i) => (
              <Dot key={i} borderRadius={4} visible={day.belongsToCurrentMonth} />
            ))}
          </Flex>
        </Flex>
      </Pressable>
    </CalendarCell>
  )
}
