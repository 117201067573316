import styled from 'styled-components'

type Attrs = {
  type: 'checkbox' | 'radio'
}

/** @deprecated */
export const HiddenInput = styled.input.attrs<Attrs>(({ type }) => ({ type }))`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  opacity: 0;
`
