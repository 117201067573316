import { colors } from '@serenis-health/tokens'
import { SelectStyleProps } from '../../types'

/** @deprecated */
export const getColorFromProps = ({ disabled }: Pick<SelectStyleProps, 'disabled'>) => {
  if (disabled) {
    return colors.grey08
  }

  return colors.black
}
