import { compareAsc, compareDesc } from 'date-fns/fp'

type KeysOfType<T, KT> = {
  [K in keyof T]: T[K] extends KT ? K : never
}[keyof T]

export const sortByDateAsc =
  <T, K extends KeysOfType<T, Date | number>>(field: K) =>
  (a: T, b: T) =>
    compareAsc(b[field] as unknown as Date, a[field] as unknown as Date)

export const sortByDateDesc =
  <T, K extends KeysOfType<T, Date | number>>(field: K) =>
  (a: T, b: T) =>
    compareDesc(b[field] as unknown as Date, a[field] as unknown as Date)
