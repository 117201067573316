import { useCallback, useEffect } from 'react'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useIntercomState } from '../hooks/useIntercomState'

export const IntercomDefaultLauncherVisibility = () => {
  const { messagesVisible, unreadCount } = useIntercomState()

  const { showDefaultLauncher } = useIntercom()

  const handleMessageVisible = useCallback(() => {
    if (messagesVisible || unreadCount) {
      showDefaultLauncher()

      return
    }
  }, [messagesVisible, showDefaultLauncher, unreadCount])

  useEffect(handleMessageVisible, [handleMessageVisible])

  return null
}
