import { useCallback } from 'react'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'

export const ContactUsButton = () => {
  const { openWithMessage } = useIntercom()

  const onClick = useCallback(() => {
    openWithMessage('intercom.iNeedSupport.message')
  }, [openWithMessage])

  return (
    <Button kind="secondary" onClick={onClick}>
      <Translation id="actions.contactUs" />
    </Button>
  )
}
