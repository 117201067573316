import { ColorName, cssvarColor } from 'design-tokens'
import { css } from 'styled-components'
import { StyledProps } from '../utils/props'

type BaseBackgroundProps = {
  backgroundColorName?: ColorName
}

export type BackgroundProps = StyledProps<BaseBackgroundProps>

export const generateBackgroundStyles = ({ backgroundColorName }: BaseBackgroundProps) => css`
  background-color: ${backgroundColorName && cssvarColor(backgroundColorName)};
`
