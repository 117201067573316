import { breakpoints } from '@serenis-health/tokens'
import { cssWithMediaQuery } from './cssWithMediaQuery'

/** @deprecated */
export const media = {
  large: cssWithMediaQuery(`(min-width: ${breakpoints.large + 1}px)`),
  medium: cssWithMediaQuery(`(min-width: ${breakpoints.medium + 1}px)`),
  mediumOnly: cssWithMediaQuery(`(min-width: ${breakpoints.medium + 1}px) and (max-width: ${breakpoints.large}px)`),
  small: cssWithMediaQuery(`(min-width: ${breakpoints.small}px)`),
  smallOnly: cssWithMediaQuery(`(min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium}px)`),
}

/** @deprecated */
export type Media = keyof typeof media
