import isPropValid from '@emotion/is-prop-valid'

const propsToInclude: string[] = ['drag', 'dragConstraints', 'dragElastic', 'dragMomentum', 'dragTransition']

const propsToExclude: string[] = [
  'active',
  'align',
  'autoWidth',
  'background',
  'backgroundActive',
  'backgroundColorName',
  'backgroundHover',
  'backgroundName',
  'basis',
  'borderAlwaysVisible',
  'borderColor',
  'borderRadius',
  'borderRadiusBottomLeft',
  'borderRadiusBottomRight',
  'borderRadiusTopLeft',
  'borderRadiusTopRight',
  'borderSize',
  'boxShadow',
  'breakWord',
  'chipKind',
  'colorName',
  'colorNameHover',
  'colorState',
  'colorState',
  'currentSlideIndex',
  'direction',
  'ellipsis',
  'fontStyle',
  'fontWeight',
  'grow',
  'hide',
  'iconBackground',
  'iconBackgroundActive',
  'iconBackgroundHover',
  'iconColorName',
  'iconColorNameActive',
  'iconColorNameHover',
  'image',
  'imageMd',
  'invalid',
  'isImageLoaded',
  'isSelected',
  'isVisible',
  'justify',
  'kind',
  'lgAlign',
  'lgBasis',
  'lgBorderColor',
  'lgBorderRadius',
  'lgBorderRadiusBottomLeft',
  'lgBorderRadiusBottomRight',
  'lgBorderRadiusTopLeft',
  'lgBorderRadiusTopRight',
  'lgBorderSize',
  'lgDirection',
  'lgGrow',
  'lgHide',
  'lgJustify',
  'lgKind',
  'lgP',
  'lgPb',
  'lgPl',
  'lgPr',
  'lgPt',
  'lgPx',
  'lgPy',
  'lgShrink',
  'lgWrap',
  'link',
  'listStyleType',
  'loading',
  'mdAlign',
  'mdAlign',
  'mdBasis',
  'mdBorderColor',
  'mdBorderRadius',
  'mdBorderRadiusBottomLeft',
  'mdBorderRadiusBottomRight',
  'mdBorderRadiusTopLeft',
  'mdBorderRadiusTopRight',
  'mdBorderSize',
  'mdDirection',
  'mdGrow',
  'mdHide',
  'mdJustify',
  'mdKind',
  'mdP',
  'mdPb',
  'mdPl',
  'mdPr',
  'mdPt',
  'mdPx',
  'mdPy',
  'mdShrink',
  'mdWrap',
  'minHeight',
  'newLine',
  'onLayout',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
  'showLine',
  'shrink',
  'size',
  'textAlign',
  'textColor',
  'textDecoration',
  'textTransform',
  'whiteSpace',
  'wrap',
  'zIndex',
]

export const shouldForwardProp = (prop: string): boolean =>
  propsToInclude.includes(prop) || (isPropValid(prop) && !propsToExclude.includes(prop))
