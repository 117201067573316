/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `(max-width: calc(601px - 1px))` */
export const MEDIA_QUERY_SM = `var(--${DESIGN_TOKENS_PREFIX}-media-query-sm)` as const
export const MEDIA_QUERY_SM_VALUE = '(max-width: calc(601px - 1px))' as const

/** `(min-width: calc(601px - 1px)) and (max-width: calc(1025px - 1px))` */
export const MEDIA_QUERY_MD = `var(--${DESIGN_TOKENS_PREFIX}-media-query-md)` as const
export const MEDIA_QUERY_MD_VALUE = '(min-width: calc(601px - 1px)) and (max-width: calc(1025px - 1px))' as const

/** `(min-width: calc(1025px - 1px)) and (max-width: calc(1441px - 1px))` */
export const MEDIA_QUERY_LG = `var(--${DESIGN_TOKENS_PREFIX}-media-query-lg)` as const
export const MEDIA_QUERY_LG_VALUE = '(min-width: calc(1025px - 1px)) and (max-width: calc(1441px - 1px))' as const

/** `(min-width: calc(1441px - 1px))` */
export const MEDIA_QUERY_XL = `var(--${DESIGN_TOKENS_PREFIX}-media-query-xl)` as const
export const MEDIA_QUERY_XL_VALUE = '(min-width: calc(1441px - 1px))' as const

/** `(min-width: 601px)` */
export const MEDIA_QUERY_GT_SM = `var(--${DESIGN_TOKENS_PREFIX}-media-query-gt-sm)` as const
export const MEDIA_QUERY_GT_SM_VALUE = '(min-width: 601px)' as const

/** `(min-width: 1025px)` */
export const MEDIA_QUERY_GT_MD = `var(--${DESIGN_TOKENS_PREFIX}-media-query-gt-md)` as const
export const MEDIA_QUERY_GT_MD_VALUE = '(min-width: 1025px)' as const

/** `(min-width: 1441px)` */
export const MEDIA_QUERY_GT_LG = `var(--${DESIGN_TOKENS_PREFIX}-media-query-gt-lg)` as const
export const MEDIA_QUERY_GT_LG_VALUE = '(min-width: 1441px)' as const

/** `(max-width: calc(601px - 1px))` */
export const MEDIA_QUERY_LT_MD = `var(--${DESIGN_TOKENS_PREFIX}-media-query-lt-md)` as const
export const MEDIA_QUERY_LT_MD_VALUE = '(max-width: calc(601px - 1px))' as const

/** `(max-width: calc(1025px - 1px))` */
export const MEDIA_QUERY_LT_LG = `var(--${DESIGN_TOKENS_PREFIX}-media-query-lt-lg)` as const
export const MEDIA_QUERY_LT_LG_VALUE = '(max-width: calc(1025px - 1px))' as const

/** `(max-width: calc(1441px - 1px))` */
export const MEDIA_QUERY_LT_XL = `var(--${DESIGN_TOKENS_PREFIX}-media-query-lt-xl)` as const
export const MEDIA_QUERY_LT_XL_VALUE = '(max-width: calc(1441px - 1px))' as const
