/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `400` */
export const FONT_WEIGHT_400 = `var(--${DESIGN_TOKENS_PREFIX}-font-weight-400)` as const
export const FONT_WEIGHT_400_VALUE = '400' as const

/** `500` */
export const FONT_WEIGHT_500 = `var(--${DESIGN_TOKENS_PREFIX}-font-weight-500)` as const
export const FONT_WEIGHT_500_VALUE = '500' as const

/** `600` */
export const FONT_WEIGHT_600 = `var(--${DESIGN_TOKENS_PREFIX}-font-weight-600)` as const
export const FONT_WEIGHT_600_VALUE = '600' as const
