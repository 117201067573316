import { useTranslation } from '~/i18n/hooks/useTranslation'
import { TranslationId, TranslationValues } from '~/i18n/types'

type Props = {
  id: TranslationId
  values?: TranslationValues
}

export const Translation = ({ id, values }: Props) => {
  const translation = useTranslation(id, values)

  return String(translation || id)
}
