import { TextAlign, typographyFlavours, TypographyKind } from '@serenis-health/tokens'
import { assertNever } from 'functions'
import { css } from 'styled-components'
import { media } from './media'

type Typography = {
  align: TextAlign
  kind: TypographyKind
}

type MdTypography = {
  mdAlign: TextAlign
  mdKind: TypographyKind
}

type LgTypography = {
  lgAlign: TextAlign
  lgKind: TypographyKind
}

/** @deprecated */
export type TypographyProps = Partial<Typography & MdTypography & LgTypography>

const switchProp = <T extends keyof Typography>(prop: T, value: Typography[T]) => {
  switch (prop) {
    case 'align':
      return `text-align: ${value};`

    case 'kind': {
      const { fontSize, lineHeight } = typographyFlavours[value as TypographyKind]

      return `
        font-size: ${fontSize};
        line-height: ${lineHeight};
      `
    }

    default:
      return assertNever(prop)
  }
}

const getPropStyle = <P extends keyof Typography>(prop: P, value?: Typography[P]) => {
  if (value === undefined) {
    return ''
  }

  // NOTE: The reason why we call a separate function instead of directly having the switch
  // statement is that the type of `prop` is not narrowed when using `P extends Keys`.
  return switchProp(prop, value)
}

/** @deprecated */
export const typography = css<TypographyProps>`
  ${({ align, kind }) => `
    ${getPropStyle('align', align)}
    ${getPropStyle('kind', kind)}
  `}

  ${({ mdAlign, mdKind }) =>
    media.medium`
      ${getPropStyle('align', mdAlign)}
      ${getPropStyle('kind', mdKind)}
    `}

  ${({ lgAlign, lgKind }) =>
    media.large`
      ${getPropStyle('align', lgAlign)}
      ${getPropStyle('kind', lgKind)}
    `}
`
