/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

export const COLOR_GREY_10_VALUE = '#FFFFFF' as const

/** @deprecated Use new token `COLOR_GREY_80_VALUE` */
export const COLOR_GREY_11_VALUE = '#5D5D98' as const

export const COLOR_GREY_20_VALUE = '#F6F7FD' as const

export const COLOR_GREY_30_VALUE = '#ECEDF9' as const

export const COLOR_GREY_40_VALUE = '#D7D7EA' as const

export const COLOR_GREY_50_VALUE = '#A7A7D2' as const

export const COLOR_GREY_60_VALUE = '#8686C1' as const

export const COLOR_GREY_70_VALUE = '#7A7AAE' as const

export const COLOR_GREY_80_VALUE = '#5D5D98' as const

export const COLOR_GREY_90_VALUE = '#3E3E79' as const

export const COLOR_GREY_100_VALUE = '#2D2D58' as const

export const COLOR_GREY_110_VALUE = '#1C1C36' as const

export const COLOR_GREY_120_VALUE = '#0B0B1E' as const

/** @deprecated Use new token `COLOR_GREY_60_VALUE` */
export const COLOR_GREY_08_VALUE = '#8686C1' as const

/** @deprecated Use new token `COLOR_GREY_50_VALUE` */
export const COLOR_GREY_06_VALUE = '#A7A7D2' as const

/** @deprecated Use new token `COLOR_GREY_40_VALUE` */
export const COLOR_GREY_04_VALUE = '#D7D7EA' as const

/** @deprecated Use new token `COLOR_GREY_30_VALUE` */
export const COLOR_GREY_02_VALUE = '#ECEDF9' as const

export const COLOR_NEUTRAL_10_VALUE = '#FFFFFF' as const

export const COLOR_NEUTRAL_20_VALUE = '#F6F7FD' as const

export const COLOR_NEUTRAL_30_VALUE = '#ECEDF9' as const

export const COLOR_NEUTRAL_40_VALUE = '#D7D7EA' as const

export const COLOR_NEUTRAL_50_VALUE = '#A7A7D2' as const

export const COLOR_NEUTRAL_60_VALUE = '#8686C1' as const

export const COLOR_NEUTRAL_70_VALUE = '#7A7AAE' as const

export const COLOR_NEUTRAL_80_VALUE = '#5D5D98' as const

export const COLOR_NEUTRAL_90_VALUE = '#3E3E79' as const

export const COLOR_NEUTRAL_100_VALUE = '#2D2D58' as const

export const COLOR_NEUTRAL_110_VALUE = '#1C1C36' as const

export const COLOR_NEUTRAL_120_VALUE = '#0B0B1E' as const

export const COLOR_ACCENT_10_VALUE = '#FFE9FD' as const

export const COLOR_ACCENT_20_VALUE = '#FFD1FB' as const

export const COLOR_ACCENT_30_VALUE = '#FEA7F9' as const

export const COLOR_ACCENT_40_VALUE = '#FD86F6' as const

export const COLOR_ACCENT_50_VALUE = '#FC8DFE' as const

export const COLOR_ACCENT_60_VALUE = '#E569DC' as const

export const COLOR_ACCENT_70_VALUE = '#CA50B7' as const

export const COLOR_ACCENT_80_VALUE = '#A83C94' as const

export const COLOR_PRIMARY_10_VALUE = '#EBE9FE' as const

export const COLOR_PRIMARY_20_VALUE = '#D9D6FE' as const

export const COLOR_PRIMARY_30_VALUE = '#BDB5FD' as const

export const COLOR_PRIMARY_40_VALUE = '#9D8CF9' as const

export const COLOR_PRIMARY_50_VALUE = '#7A59F5' as const

export const COLOR_PRIMARY_60_VALUE = '#6B3BEC' as const

export const COLOR_PRIMARY_70_VALUE = '#5D29D8' as const

export const COLOR_PRIMARY_80_VALUE = '#4D22B5' as const

/** @deprecated Use new token `COLOR_GREEN_80_VALUE` */
export const COLOR_GREEN_10_VALUE = '#227251' as const

export const COLOR_GREEN_20_VALUE = '#C8EEDE' as const

export const COLOR_GREEN_40_VALUE = '#7AD7AF' as const

export const COLOR_GREEN_60_VALUE = '#419F77' as const

export const COLOR_GREEN_80_VALUE = '#227251' as const

/** @deprecated Use new token `COLOR_GREEN_60_VALUE` */
export const COLOR_GREEN_08_VALUE = '#419F77' as const

/** @deprecated Use new token `COLOR_GREEN_40_VALUE` */
export const COLOR_GREEN_06_VALUE = '#7AD7AF' as const

/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_04_VALUE = '#C8EEDE' as const

/** @deprecated Use new token `COLOR_GREEN_20_VALUE` */
export const COLOR_GREEN_02_VALUE = '#C8EEDE' as const

/** @deprecated Use new token `COLOR_YELLOW_80_VALUE` */
export const COLOR_YELLOW_10_VALUE = '#D05E06' as const

export const COLOR_YELLOW_20_VALUE = '#FFDF99' as const

export const COLOR_YELLOW_40_VALUE = '#FFCA5C' as const

export const COLOR_YELLOW_60_VALUE = '#FFAB00' as const

export const COLOR_YELLOW_80_VALUE = '#D05E06' as const

/** @deprecated Use new token `COLOR_YELLOW_60_VALUE` */
export const COLOR_YELLOW_08_VALUE = '#FFAB00' as const

/** @deprecated Use new token `COLOR_YELLOW_40_VALUE` */
export const COLOR_YELLOW_06_VALUE = '#FFCA5C' as const

/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_04_VALUE = '#FFDF99' as const

/** @deprecated Use new token `COLOR_YELLOW_20_VALUE` */
export const COLOR_YELLOW_02_VALUE = '#FFDF99' as const

export const COLOR_BLUE_20_VALUE = '#CCE7FF' as const

export const COLOR_BLUE_40_VALUE = '#66B8FF' as const

export const COLOR_BLUE_60_VALUE = '#007AFF' as const

export const COLOR_BLUE_80_VALUE = '#004299' as const

export const COLOR_RED_20_VALUE = '#FFE2D4' as const

export const COLOR_RED_40_VALUE = '#FFC0A8' as const

export const COLOR_RED_60_VALUE = '#FF5630' as const

export const COLOR_RED_80_VALUE = '#D01406' as const

export const ELEVATION_XXS_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 8px -2px rgb(from #5D29D8 r g b / 8%), 0 0 2px 0 rgb(from #5D29D8 r g b / 24%), 0 2px 2px -4px rgb(from #5D29D8 r g b / 64%)' as const

export const ELEVATION_XS_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 4px 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 2px 0 rgb(from #5D29D8 r g b / 24%), 0 2px 8px -4px rgb(from #5D29D8 r g b / 32%)' as const

export const ELEVATION_SM_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 8px -2px rgb(from #5D29D8 r g b / 24%), 0 12px 8px -12px rgb(from #5D29D8 r g b / 24%)' as const

export const ELEVATION_MD_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 16px -4px rgb(from #5D29D8 r g b / 24%), 0 16px 12px -16px rgb(from #5D29D8 r g b / 16%)' as const

export const ELEVATION_LG_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 24px -4px rgb(from #5D29D8 r g b / 16%), 0 16px 24px -16px rgb(from #5D29D8 r g b / 16%)' as const

export const ELEVATION_XL_VALUE =
  'inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 32px -8px rgb(from #5D29D8 r g b / 16%), 0 32px 32px -24px rgb(from #5D29D8 r g b / 16%)' as const

export const COLOR_MINTTEA_20_VALUE = '#CAFACB' as const

export const COLOR_MINTTEA_40_VALUE = '#179A92' as const

export const COLOR_MINTTEA_60_VALUE = '#003B2E' as const

export const COLOR_MINTTEA_80_VALUE = '#061A1D' as const

export const COLOR_ACCENT_VALUE = '#FC8DFE' as const

export const COLOR_PRIMARY_VALUE = '#7A59F5' as const

export const COLOR_LIGHTER_VALUE = '#FFFFFF' as const

/** @deprecated Use new token `COLOR_LIGHTER_VALUE` */
export const COLOR_WHITE_VALUE = '#FFFFFF' as const

export const COLOR_DARKER_VALUE = '#0B0B1E' as const

/** @deprecated Use new token `COLOR_DARKER_VALUE` */
export const COLOR_BLACK_VALUE = '#0B0B1E' as const

export const COLOR_TRANSPARENT_VALUE = 'rgba(0, 0, 0, 0)' as const

export const COLOR_SUCCESS_VALUE = '#419F77' as const

export const COLOR_INFO_VALUE = '#007AFF' as const

export const COLOR_WARNING_VALUE = '#FFAB00' as const

export const COLOR_ERROR_VALUE = '#FF5630' as const

/** @deprecated Use new token `COLOR_PRIMARY_80_VALUE` */
export const COLOR_PURPLE_10_VALUE = '#4D22B5' as const

/** @deprecated Use new token `COLOR_PRIMARY_50_VALUE` */
export const COLOR_PURPLE_08_VALUE = '#7A59F5' as const

/** @deprecated Use new token `COLOR_PRIMARY_40_VALUE` */
export const COLOR_PURPLE_06_VALUE = '#9D8CF9' as const

/** @deprecated Use new token `COLOR_PRIMARY_30_VALUE` */
export const COLOR_PURPLE_04_VALUE = '#BDB5FD' as const

/** @deprecated Use new token `COLOR_PRIMARY_10_VALUE` */
export const COLOR_PURPLE_02_VALUE = '#EBE9FE' as const

/** @deprecated Use new token `COLOR_RED_80_VALUE` */
export const COLOR_CORAL_10_VALUE = '#D01406' as const

/** @deprecated Use new token `COLOR_RED_60_VALUE` */
export const COLOR_CORAL_08_VALUE = '#FF5630' as const

/** @deprecated Use new token `COLOR_RED_40_VALUE` */
export const COLOR_CORAL_06_VALUE = '#FFC0A8' as const

/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_04_VALUE = '#FFE2D4' as const

/** @deprecated Use new token `COLOR_RED_20_VALUE` */
export const COLOR_CORAL_02_VALUE = '#FFE2D4' as const
