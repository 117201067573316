import { Flex } from 'cdk'
import { Loader } from 'ui-deprecated'

/**
 * @link https://linear.app/serenis/issue/PRD-5758/move-ui-deprecated-in-app-web
 */
export const CenteredLoader = () => (
  <Flex $align="center" $grow={1} $justify="center" $shrink={1}>
    <Loader colorName="purple08" size={32} />
  </Flex>
)
