import { ColorName, colors, Spacing } from '@serenis-health/tokens'
import { assertNever } from 'functions'
import { css } from 'styled-components'
import { media } from './media'

type Border = {
  borderColor: ColorName
  borderRadius: number
  borderRadiusBottomLeft: Spacing
  borderRadiusBottomRight: Spacing
  borderRadiusTopLeft: Spacing
  borderRadiusTopRight: Spacing
  borderSize: Spacing
}

type MdBorder = {
  mdBorderColor: ColorName
  mdBorderRadius: number
  mdBorderRadiusBottomLeft: Spacing
  mdBorderRadiusBottomRight: Spacing
  mdBorderRadiusTopLeft: Spacing
  mdBorderRadiusTopRight: Spacing
  mdBorderSize: Spacing
}

type LgBorder = {
  lgBorderColor: ColorName
  lgBorderRadius: number
  lgBorderRadiusBottomLeft: Spacing
  lgBorderRadiusBottomRight: Spacing
  lgBorderRadiusTopLeft: Spacing
  lgBorderRadiusTopRight: Spacing
  lgBorderSize: Spacing
}

/** @deprecated Use the `cdk` one */
export type BorderProps = Partial<Border & MdBorder & LgBorder>

const switchProp = (prop: keyof Border, value: Border[keyof Border]) => {
  switch (prop) {
    case 'borderColor':
      return `border-color: ${colors[value as Border['borderColor']]};`
    case 'borderRadius':
      return `border-radius: ${value}px;`
    case 'borderRadiusBottomLeft':
      return `border-bottom-left-radius: ${value}px;`
    case 'borderRadiusBottomRight':
      return `border-bottom-right-radius: ${value}px;`
    case 'borderRadiusTopLeft':
      return `border-top-left-radius: ${value}px;`
    case 'borderRadiusTopRight':
      return `border-top-right-radius: ${value}px;`
    case 'borderSize':
      return `
        border-style: solid;
        border-width: ${value}px;
      `
    default:
      return assertNever(prop)
  }
}

const getPropStyle = <P extends keyof Border>(prop: P, value?: Border[P]) => {
  if (value === undefined) {
    return ''
  }

  // NOTE: The reason why we call a separate function instead of directly having the switch
  // statement is that the type of `prop` is not narrowed when using `P extends Keys`.
  return switchProp(prop, value)
}

type OverflowHiddenProps = Pick<
  BorderProps,
  'borderRadius' | 'borderRadiusBottomLeft' | 'borderRadiusBottomRight' | 'borderRadiusTopLeft' | 'borderRadiusTopRight'
>

const getOverflowHidden = ({
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  borderRadiusTopLeft,
  borderRadiusTopRight,
}: OverflowHiddenProps) => {
  if (
    [borderRadius, borderRadiusBottomLeft, borderRadiusBottomRight, borderRadiusTopLeft, borderRadiusTopRight].every(
      (value) => value === undefined,
    )
  ) {
    return ''
  }

  return 'overflow: hidden;'
}

/** @deprecated Use the `cdk` one */
export const border = css<BorderProps>`
  ${({
    borderColor,
    borderRadius,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderSize,
  }) => `
    ${getOverflowHidden({
      borderRadius,
      borderRadiusBottomLeft,
      borderRadiusBottomRight,
      borderRadiusTopLeft,
      borderRadiusTopRight,
    })}
    ${getPropStyle('borderColor', borderColor)}
    ${getPropStyle('borderRadius', borderRadius)}
    ${getPropStyle('borderRadiusBottomLeft', borderRadiusBottomLeft)}
    ${getPropStyle('borderRadiusBottomRight', borderRadiusBottomRight)}
    ${getPropStyle('borderRadiusTopLeft', borderRadiusTopLeft)}
    ${getPropStyle('borderRadiusTopRight', borderRadiusTopRight)}
    ${getPropStyle('borderSize', borderSize)}
  `}

  ${({
    mdBorderColor,
    mdBorderRadius,
    mdBorderRadiusBottomLeft,
    mdBorderRadiusBottomRight,
    mdBorderRadiusTopLeft,
    mdBorderRadiusTopRight,
    mdBorderSize,
  }) => media.medium`
    ${getOverflowHidden({
      borderRadius: mdBorderRadius,
      borderRadiusBottomLeft: mdBorderRadiusBottomLeft,
      borderRadiusBottomRight: mdBorderRadiusBottomRight,
      borderRadiusTopLeft: mdBorderRadiusTopLeft,
      borderRadiusTopRight: mdBorderRadiusTopRight,
    })}
    ${getPropStyle('borderColor', mdBorderColor)}
    ${getPropStyle('borderRadius', mdBorderRadius)}
    ${getPropStyle('borderRadiusBottomLeft', mdBorderRadiusBottomLeft)}
    ${getPropStyle('borderRadiusBottomRight', mdBorderRadiusBottomRight)}
    ${getPropStyle('borderRadiusTopLeft', mdBorderRadiusTopLeft)}
    ${getPropStyle('borderRadiusTopRight', mdBorderRadiusTopRight)}
    ${getPropStyle('borderSize', mdBorderSize)}
  `}

  ${({
    lgBorderColor,
    lgBorderRadius,
    lgBorderRadiusBottomLeft,
    lgBorderRadiusBottomRight,
    lgBorderRadiusTopLeft,
    lgBorderRadiusTopRight,
    lgBorderSize,
  }) => media.large`
    ${getOverflowHidden({
      borderRadius: lgBorderRadius,
      borderRadiusBottomLeft: lgBorderRadiusBottomLeft,
      borderRadiusBottomRight: lgBorderRadiusBottomRight,
      borderRadiusTopLeft: lgBorderRadiusTopLeft,
      borderRadiusTopRight: lgBorderRadiusTopRight,
    })}
    ${getPropStyle('borderColor', lgBorderColor)}
    ${getPropStyle('borderRadius', lgBorderRadius)}
    ${getPropStyle('borderRadiusBottomLeft', lgBorderRadiusBottomLeft)}
    ${getPropStyle('borderRadiusBottomRight', lgBorderRadiusBottomRight)}
    ${getPropStyle('borderRadiusTopLeft', lgBorderRadiusTopLeft)}
    ${getPropStyle('borderRadiusTopRight', lgBorderRadiusTopRight)}
    ${getPropStyle('borderSize', lgBorderSize)}
  `}
`
