import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisHomeSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M10.396 2.06a3.954 3.954 0 0 1 3.206 0c3.474 1.543 6.103 3.565 7.464 4.735.83.713 1.28 1.73 1.335 2.787.064 1.222.142 3.136.142 5.23 0 1.612-.046 3.167-.097 4.386a3.366 3.366 0 0 1-3.259 3.235c-1.63.055-4.045.111-7.188.111-3.144 0-5.559-.056-7.188-.111a3.366 3.366 0 0 1-3.26-3.235c-.05-1.219-.096-2.774-.096-4.385 0-2.095.078-4.009.142-5.231.055-1.057.505-2.074 1.335-2.787 1.36-1.17 3.99-3.192 7.463-4.735ZM5.998 17a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2h-10a1 1 0 0 1-1-1Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
