import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const Visa = ({ width }: Props) => (
  <svg
    data-test-id="icon-visa"
    fill="none"
    version="1.1"
    viewBox="0 0 33 11"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1481 0.710526C18.8911 0.710526 16.8742 1.84288 16.8742 3.93501C16.8742 6.33427 20.4513 6.49999 20.4513 7.70532C20.4513 8.21283 19.8504 8.66713 18.8242 8.66713C17.3678 8.66713 16.2793 8.03233 16.2793 8.03233L15.8135 10.1435C15.8135 10.1435 17.0675 10.6797 18.7323 10.6797C21.1999 10.6797 23.1416 9.49177 23.1416 7.36387C23.1416 4.82861 19.5496 4.66782 19.5496 3.54908C19.5496 3.15152 20.0428 2.71591 21.0661 2.71591C22.2207 2.71591 23.1627 3.17758 23.1627 3.17758L23.6185 1.13853C23.6185 1.13853 22.5935 0.710526 21.1481 0.710526V0.710526ZM0.499108 0.864416L0.444458 1.1722C0.444458 1.1722 1.39396 1.34041 2.24915 1.67595C3.35027 2.06071 3.42871 2.2847 3.61415 2.98041L5.63496 10.521H8.34385L12.5171 0.864416H9.81443L7.13286 7.43L6.03862 1.8647C5.93827 1.22776 5.42996 0.864416 4.80776 0.864416H0.499108V0.864416ZM13.6039 0.864416L11.4837 10.521H14.061L16.1737 0.864416H13.6039V0.864416ZM27.9781 0.864416C27.3566 0.864416 27.0273 1.18648 26.7857 1.74928L23.0099 10.521H25.7126L26.2355 9.05907H29.5281L29.8461 10.521H32.2308L30.1504 0.864416H27.9781V0.864416ZM28.3296 3.47334L29.1307 7.09697H26.9844L28.3296 3.47334V3.47334Z"
      fill="#1434CB"
    />
  </svg>
)
