import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathMyself = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-myself"
    fill="none"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M38.57 20a18.57 18.57 0 1 1-37.14 0 18.57 18.57 0 0 1 37.14 0Z" stroke={color} strokeWidth="2.86" />
  </svg>
)
