import styled from 'styled-components'
import { View } from '../../View'
import { HiddenInput } from './HiddenInput'
import { StyledLabel } from './StyledLabel'

/** @deprecated */
export const StyledView = styled(View)`
  ${HiddenInput}:checked + ${StyledLabel}:after {
    left: 44px;
    transform: translateX(-100%);
  }
`
