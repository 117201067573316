/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { SpacingName } from '../types/spacing'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_SPACING_PREFIX = 'spacing'

export const cssvarNameSpacing = (spacing: SpacingName) => cssvarName(`${DESIGN_TOKENS_SPACING_PREFIX}-${spacing}`)

export const cssvarValueSpacing = (spacing: SpacingName) => cssvarValue(`${DESIGN_TOKENS_SPACING_PREFIX}-${spacing}`)

export const cssvarSpacing = (spacing: SpacingName) => cssvar(`${DESIGN_TOKENS_SPACING_PREFIX}-${spacing}`)
