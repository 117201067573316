import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PoweredByStripe = ({ width }: Props) => (
  <svg
    data-test-id="icon-powered-by-stripe"
    fill="none"
    version="1.1"
    viewBox="0 0 98 14"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.01723 2.17957H0.690552V10.6264H2.17595V7.36071H4.01723C5.87398 7.36071 7.03444 6.45009 7.03444 4.77014C7.03444 3.09019 5.87398 2.17957 4.01723 2.17957ZM3.93986 6.10468H2.17595V3.5141H3.96307C5.0307 3.5141 5.59546 3.97727 5.59546 4.80939C5.59546 5.64151 5.00749 6.06543 3.96307 6.06543L3.93986 6.10468Z"
      fill="black"
    />
    <path
      d="M10.2294 4.34629C9.82269 4.34152 9.41949 4.42311 9.04569 4.58584C8.6719 4.74857 8.33578 4.98883 8.05892 5.2912C7.78207 5.59356 7.5706 5.95135 7.4381 6.34156C7.30561 6.73178 7.25501 7.1458 7.28956 7.55703C7.25329 7.97053 7.30225 8.38715 7.43332 8.7804C7.56439 9.17364 7.7747 9.5349 8.05086 9.84117C8.32703 10.1474 8.663 10.392 9.0374 10.5594C9.4118 10.7267 9.81643 10.8131 10.2255 10.8131C10.6347 10.8131 11.0393 10.7267 11.4137 10.5594C11.7881 10.392 12.1241 10.1474 12.4002 9.84117C12.6764 9.5349 12.8867 9.17364 13.0178 8.7804C13.1488 8.38715 13.1978 7.97053 13.1615 7.55703C13.1972 7.14621 13.1477 6.73235 13.0161 6.34214C12.8845 5.95193 12.6738 5.59404 12.3975 5.29153C12.1213 4.98902 11.7856 4.74861 11.4122 4.5858C11.0387 4.423 10.6358 4.34141 10.2294 4.34629V4.34629ZM10.2294 9.58239C9.28556 9.58239 8.68212 8.79737 8.68212 7.55703C8.68212 6.3167 9.27009 5.53168 10.2294 5.53168C11.1887 5.53168 11.7767 6.3167 11.7767 7.55703C11.7767 8.79737 11.1655 9.57454 10.2294 9.57454V9.58239Z"
      fill="black"
    />
    <path
      d="M19.2654 8.54618L18.1745 4.44836H17.0295L15.9464 8.54618L14.8479 4.44836H13.4166L15.2502 10.6343H16.4571L17.5711 6.58363L18.6851 10.6343H19.892L21.7256 4.44836H20.3485L19.2654 8.54618Z"
      fill="black"
    />
    <path
      d="M24.8514 4.34637C24.45 4.35394 24.0543 4.4442 23.6884 4.61166C23.3224 4.77913 22.9938 5.02032 22.7224 5.32059C22.4511 5.62086 22.2428 5.97394 22.11 6.3584C21.9773 6.74286 21.9229 7.15069 21.9503 7.55712C21.9179 7.96983 21.9708 8.38485 22.1056 8.77563C22.2404 9.16641 22.454 9.52435 22.7329 9.82655C23.0118 10.1288 23.3498 10.3686 23.7252 10.5307C24.1007 10.6927 24.5053 10.7735 24.9133 10.7679C25.4742 10.7985 26.0305 10.6499 26.5037 10.3429C26.977 10.0359 27.3434 9.58596 27.5515 9.05651L26.3987 8.56979C26.3083 8.87315 26.1189 9.13629 25.8616 9.31602C25.6043 9.49575 25.2945 9.58141 24.983 9.55892C24.761 9.5643 24.5404 9.52391 24.3343 9.44021C24.1282 9.3565 23.941 9.23122 23.7841 9.07195C23.6271 8.91268 23.5036 8.72274 23.4211 8.51363C23.3386 8.30452 23.2988 8.0806 23.3041 7.85542H27.5747V7.38441C27.5901 5.68876 26.6463 4.34637 24.8514 4.34637ZM23.3583 6.80349C23.4125 6.44302 23.5937 6.11471 23.8683 5.87943C24.1428 5.64414 24.4921 5.51783 24.8514 5.52391C25.0222 5.50927 25.194 5.53168 25.3555 5.58965C25.517 5.64762 25.6646 5.73983 25.7882 5.86013C25.9119 5.98043 26.0089 6.12608 26.0729 6.28738C26.1368 6.44869 26.1661 6.62195 26.1589 6.79565L23.3583 6.80349Z"
      fill="black"
    />
    <path
      d="M30.0267 5.36678V4.4483H28.6342V10.6264H30.0267V7.38428C30.0143 7.17482 30.0458 6.96507 30.1192 6.76887C30.1926 6.57268 30.3061 6.39451 30.4523 6.24613C30.5986 6.09775 30.7742 5.98253 30.9675 5.90808C31.1609 5.83363 31.3676 5.80165 31.574 5.81424C31.744 5.80205 31.9146 5.80205 32.0846 5.81424V4.4012C31.9763 4.4012 31.8525 4.4012 31.6901 4.4012C31.3507 4.38336 31.0135 4.4649 30.7186 4.6361C30.4237 4.80729 30.1835 5.06093 30.0267 5.36678V5.36678Z"
      fill="black"
    />
    <path
      d="M35.2798 4.34637C34.8784 4.35394 34.4827 4.4442 34.1167 4.61166C33.7507 4.77913 33.4221 5.02032 33.1508 5.32059C32.8795 5.62086 32.6711 5.97394 32.5384 6.3584C32.4056 6.74286 32.3513 7.15069 32.3786 7.55712C32.3463 7.96983 32.3992 8.38485 32.534 8.77563C32.6687 9.16641 32.8824 9.52435 33.1613 9.82655C33.4402 10.1288 33.7781 10.3686 34.1536 10.5307C34.529 10.6927 34.9337 10.7735 35.3417 10.7679C35.8974 10.7918 36.4468 10.6401 36.9138 10.3335C37.3809 10.0269 37.7426 9.5807 37.9489 9.05651L36.7574 8.59335C36.667 8.8967 36.4776 9.15984 36.2203 9.33957C35.9631 9.51931 35.6532 9.60496 35.3417 9.58248C35.1243 9.58046 34.9095 9.53461 34.7097 9.4476C34.51 9.36059 34.3293 9.23416 34.1781 9.07565C34.0269 8.91714 33.9083 8.72972 33.8291 8.52428C33.75 8.31885 33.7119 8.09949 33.717 7.87898H38.003V7.40796C38.003 5.68876 37.0437 4.34637 35.2798 4.34637ZM33.7866 6.80349C33.8424 6.44581 34.0232 6.12044 34.2959 5.88692C34.5687 5.65339 34.9152 5.52732 35.272 5.53176C35.4428 5.51712 35.6146 5.53953 35.7761 5.5975C35.9377 5.65547 36.0852 5.74768 36.2088 5.86798C36.3325 5.98828 36.4296 6.13393 36.4935 6.29523C36.5574 6.45654 36.5867 6.6298 36.5795 6.80349H33.7866Z"
      fill="black"
    />
    <path
      d="M43.0785 5.13125C42.8638 4.87563 42.5952 4.67233 42.2925 4.53643C41.9898 4.40053 41.6609 4.33553 41.3301 4.34623C39.6203 4.34623 38.6455 5.79852 38.6455 7.55697C38.6455 9.31542 39.6203 10.7677 41.3301 10.7677C41.6611 10.7801 41.9906 10.7159 42.2935 10.5799C42.5965 10.4439 42.8649 10.2397 43.0785 9.98269V10.6264H44.4711V2.17957H43.0785V5.13125ZM43.0785 7.76108C43.1097 7.98491 43.0929 8.21295 43.0292 8.42959C42.9656 8.64622 42.8566 8.84636 42.7096 9.01631C42.5627 9.18625 42.3814 9.322 42.178 9.41427C41.9746 9.50654 41.754 9.55315 41.5312 9.55093C40.5177 9.55093 39.9839 8.76591 39.9839 7.57267C39.9839 6.37944 40.5255 5.59441 41.5312 5.59441C42.3899 5.59441 43.0785 6.23028 43.0785 7.39211V7.76108Z"
      fill="black"
    />
    <path
      d="M52.1691 4.34623C51.832 4.33127 51.4961 4.39415 51.1865 4.53014C50.8769 4.66612 50.6018 4.87166 50.3819 5.13125V2.17957H48.9894V10.6264H50.3819V9.97484C50.5978 10.2311 50.8678 10.4347 51.1718 10.5706C51.4758 10.7065 51.8061 10.7712 52.1381 10.7599C53.8401 10.7599 54.8149 9.29972 54.8149 7.54912C54.8149 5.79852 53.8711 4.34623 52.1691 4.34623ZM51.9292 9.52738C51.7065 9.52961 51.4858 9.48299 51.2824 9.39072C51.0791 9.29845 50.8977 9.1627 50.7508 8.99275C50.6039 8.82281 50.4949 8.62267 50.4312 8.40603C50.3675 8.1894 50.3507 7.96136 50.3819 7.73753V7.36857C50.3819 6.20673 51.0318 5.57086 51.9292 5.57086C52.935 5.57086 53.4765 6.35589 53.4765 7.54912C53.4765 8.74235 52.8963 9.52738 51.9292 9.52738Z"
      fill="black"
    />
    <path
      d="M58.0641 8.78954L56.571 4.44836H55.1011L57.3446 10.4067L57.1126 10.9876C57.0731 11.1866 56.9592 11.3624 56.7948 11.4781C56.6303 11.5937 56.4281 11.6402 56.2306 11.6078C56.0761 11.6192 55.9209 11.6192 55.7664 11.6078V12.7932C55.9533 12.8214 56.1422 12.8345 56.3312 12.8324C56.8142 12.868 57.2942 12.7303 57.6873 12.4434C58.0804 12.1564 58.3617 11.7384 58.4819 11.2624L60.9885 4.49547H59.5263L58.0641 8.78954Z"
      fill="black"
    />
    <path
      d="M87.5168 3.1137C87.2114 3.11191 86.9086 3.17132 86.6258 3.28852C86.343 3.40571 86.0858 3.5784 85.8689 3.79667L85.7606 3.25501H83.9116V13.3975L86.0159 12.9343V10.4772C86.4345 10.8329 86.9632 11.0274 87.5091 11.0267C89.0099 11.0267 90.387 9.77854 90.387 7.01526C90.387 4.48749 88.9945 3.1137 87.5168 3.1137ZM87.0139 9.10342C86.8294 9.11422 86.6447 9.08319 86.4735 9.01258C86.3022 8.94197 86.1485 8.83356 86.0237 8.69521V5.46877C86.1491 5.32536 86.3044 5.21217 86.4784 5.13747C86.6524 5.06278 86.8407 5.02845 87.0294 5.03701C87.8031 5.03701 88.3291 5.92408 88.3291 7.06236C88.3291 8.20065 87.7953 9.10342 87.0139 9.10342Z"
      fill="black"
    />
    <path
      d="M94.2708 3.11366C92.2439 3.11366 91.0138 4.88781 91.0138 7.12513C91.0138 9.77065 92.4682 11.113 94.5416 11.113C95.3508 11.1264 96.1499 10.9291 96.8626 10.54V8.77368C96.2153 9.10503 95.4985 9.27205 94.7737 9.26039C93.9382 9.26039 93.2264 8.95423 93.1181 7.92585H97.2803C97.2803 7.8081 97.2803 7.34493 97.2803 7.14083C97.3499 4.88781 96.2978 3.11366 94.2708 3.11366ZM93.1336 6.3087C93.1336 5.31958 93.7293 4.90351 94.2554 4.90351C94.7815 4.90351 95.3385 5.31958 95.3385 6.3087H93.1336Z"
      fill="black"
    />
    <path
      d="M78.2329 3.84367L78.1014 3.19995H76.3065V10.8225H78.3799V5.65707C78.5671 5.42639 78.8193 5.25919 79.1027 5.17783C79.3861 5.09647 79.6873 5.10482 79.9659 5.20176V3.19995C79.6493 3.07414 79.2984 3.07014 78.9792 3.18872C78.66 3.30729 78.3945 3.54024 78.2329 3.84367V3.84367Z"
      fill="black"
    />
    <path
      d="M67.7573 5.49232C67.7573 5.14691 68.0358 5.01345 68.4768 5.01345C69.2076 5.02941 69.924 5.22326 70.5656 5.57867V3.51406C69.9022 3.24189 69.1923 3.10581 68.4768 3.1137C66.767 3.1137 65.6298 4.04002 65.6298 5.59437C65.6298 8.02794 68.8327 7.63543 68.8327 8.67951C68.8327 9.08772 68.4923 9.22117 68.059 9.22117C67.2416 9.1727 66.4468 8.93074 65.7381 8.51465V10.6264C66.4685 10.9569 67.2592 11.1281 68.059 11.1288C69.8075 11.1288 71.0143 10.226 71.0143 8.64811C70.9757 6.01043 67.7573 6.48144 67.7573 5.49232Z"
      fill="black"
    />
    <path
      d="M73.8074 1.39449L71.7573 1.8498V8.86005C71.7467 9.15548 71.7956 9.45 71.901 9.7256C72.0064 10.0012 72.1661 10.2521 72.3703 10.463C72.5745 10.6738 72.8189 10.8402 73.0886 10.952C73.3583 11.0638 73.6477 11.1186 73.939 11.1131C74.4472 11.1361 74.9536 11.0366 75.4166 10.8226V9.05631C75.1458 9.17406 73.8229 9.57442 73.8229 8.27128V5.13119H75.4166V3.29424H73.8229L73.8074 1.39449Z"
      fill="black"
    />
    <path d="M80.7473 2.5406L82.8594 2.09314V0.397491L80.7473 0.844954V2.5406Z" fill="black" />
    <path d="M82.8594 3.25504H80.7473V10.8227H82.8594V3.25504Z" fill="black" />
  </svg>
)
