import { BORDER_RADIUS_CIRCLE, COLOR_PRIMARY_80, ColorName, cssvarColor } from 'design-tokens'
import { Icon } from 'icons'
import { ComponentProps } from 'react'
import { slugify } from 'strings'
import styled, { css } from 'styled-components'
import { Text } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

type CircleBaseProps = {
  backgroundColor?: ColorName
  size: 18 | 24 | 32
}

type CircleProps =
  | (CircleBaseProps & {
      borderSize?: never
      borderColor?: never
    })
  | (CircleBaseProps & {
      borderSize: 1
      borderColor: ColorName
    })

const Circle = styled.div.withConfig({ displayName: 'IconCircled', shouldForwardProp })<CircleProps>`
  display: flex;
  flex: ${({ size }) => `0 0 ${size}px`};
  justify-content: center;
  align-items: center;
  height: ${({ size }) => `${size}px`};
  border-radius: ${BORDER_RADIUS_CIRCLE};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${cssvarColor(backgroundColor)};
    `}

  ${({ borderColor, borderSize }) =>
    borderColor &&
    borderSize &&
    css`
      border: ${borderSize}px solid ${cssvarColor(borderColor)};
    `}
`

type DashedLineProps = {
  left: 12 | 16
}

const DashedLine = styled.div.withConfig({ displayName: 'DashedLine', shouldForwardProp })<DashedLineProps>`
  position: absolute;
  bottom: 100%;
  left: ${({ left }) => `${left}px`};
  z-index: -1;
  width: 0;
  height: 9999px; /* Note: unknown items height: fixed with so 9999px and crop with overflow hidden */
  border-left: 1px dashed ${COLOR_PRIMARY_80};
`

const Item = styled.div.withConfig({ displayName: 'Item', shouldForwardProp })`
  position: relative;
  display: flex;
  flex-direction: 'row';
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`

const Container = styled.div.withConfig({ displayName: 'Container', shouldForwardProp })`
  position: relative;
  overflow: hidden;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`

type Props = {
  items: {
    iconName?: ComponentProps<typeof Icon>['name']
    description: string
    title?: string
  }[]
}

export const Timeline = ({ items }: Props) => (
  <Container>
    {items.map(({ description, iconName, title }, index) => (
      <Item key={slugify(description)}>
        {index === items.length - 1 && <DashedLine left={iconName ? 16 : 12} />}

        {iconName ? (
          <Circle backgroundColor="primary-10" borderColor="primary-40" borderSize={1} size={32}>
            <Icon colorName="primary-50" name={iconName} size={20} />
          </Circle>
        ) : (
          <Circle backgroundColor="lighter" borderColor="primary-40" borderSize={1} size={24}>
            <Circle backgroundColor="primary-40" size={18} />
          </Circle>
        )}

        <TextContainer>
          <Text colorName="grey-80" fontWeight="600" kind="caption">
            {title}
          </Text>

          <Text colorName="darker" kind="caption">
            {description}
          </Text>
        </TextContainer>
      </Item>
    ))}
  </Container>
)
