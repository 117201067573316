import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const Mastercard = ({ width }: Props) => (
  <svg
    data-test-id="icon-mastercard"
    fill="none"
    version="1.1"
    viewBox="0 0 21 14"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.432 1.63942H8.01733V11.709H13.432V1.63942Z" fill="#FF5F00" />
    <path
      d="M8.36573 6.67378C8.36487 5.70401 8.57722 4.74675 8.98671 3.87447C9.39619 3.00218 9.99208 2.23774 10.7293 1.63901C9.81637 0.896449 8.72002 0.43466 7.56551 0.306426C6.411 0.178192 5.24493 0.388684 4.20056 0.913852C3.15619 1.43902 2.27566 2.25767 1.65963 3.27623C1.0436 4.29479 0.716919 5.47216 0.716919 6.67378C0.716919 7.87539 1.0436 9.05277 1.65963 10.0713C2.27566 11.0899 3.15619 11.9085 4.20056 12.4337C5.24493 12.9589 6.411 13.1694 7.56551 13.0411C8.72002 12.9129 9.81637 12.4511 10.7293 11.7085C9.99208 11.1098 9.3962 10.3454 8.98672 9.47309C8.57723 8.6008 8.36488 7.64355 8.36573 6.67378V6.67378Z"
      fill="#EB001B"
    />
    <path
      d="M20.7368 6.67378C20.7368 7.87538 20.4102 9.05274 19.7942 10.0713C19.1782 11.0899 18.2977 11.9085 17.2533 12.4337C16.209 12.9589 15.0429 13.1694 13.8884 13.0411C12.7339 12.9129 11.6376 12.4511 10.7247 11.7085C11.4613 11.1092 12.0567 10.3446 12.4661 9.4725C12.8755 8.60035 13.0882 7.64339 13.0882 6.67378C13.0882 5.70416 12.8755 4.7472 12.4661 3.87506C12.0567 3.00291 11.4613 2.23835 10.7247 1.63901C11.6376 0.896447 12.7339 0.434657 13.8884 0.306425C15.0429 0.178192 16.209 0.388693 17.2533 0.913866C18.2977 1.43904 19.1782 2.25769 19.7942 3.27625C20.4102 4.29481 20.7368 5.47218 20.7368 6.67378V6.67378Z"
      fill="#F79E1B"
    />
    <path
      d="M20.1548 10.6433V10.4371H20.2351V10.3951H20.0305V10.4371H20.1109V10.6433H20.1548ZM20.5519 10.6433V10.3947H20.4892L20.4171 10.5657L20.345 10.3947H20.2822V10.6433H20.3265V10.4558L20.3941 10.6175H20.44L20.5077 10.4554V10.6433H20.5519Z"
      fill="#F79E1B"
    />
  </svg>
)
