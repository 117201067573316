import { css, CSSProperties } from 'styled-components'
import { StyledProps } from '../utils/props'
import { toValueOrPX } from '../utils/toValueOrPX'

type BasePositionProps = {
  position?: CSSProperties['position']
  top?: CSSProperties['top']
  right?: CSSProperties['right']
  bottom?: CSSProperties['bottom']
  left?: CSSProperties['left']
  zIndex?: CSSProperties['zIndex']
}

export type PositionProps = StyledProps<BasePositionProps>

export const generatePositionStyles = ({ position, top, right, bottom, left, zIndex }: BasePositionProps) => css`
  position: ${position};
  top: ${toValueOrPX(top)};
  right: ${toValueOrPX(right)};
  bottom: ${toValueOrPX(bottom)};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  left: ${toValueOrPX(left)};
  z-index: ${zIndex};
`
