import styled from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { Flex, FlexProps } from './Flex'

type Props = Pick<FlexProps, 'borderColor'>

const defaultProps: Partial<FlexProps> = {
  borderColor: 'purple04',
}

/** @deprecated Use the `ui` one */
export const Divider = styled(Flex)
  .withConfig({
    displayName: 'Divider',
    shouldForwardProp,
  })
  .attrs<Props>((props) => ({ ...defaultProps, ...props, as: 'hr' }))`
  border-top: none;
  margin: 0;
`
