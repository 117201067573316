import { COLOR_DARKER, COLOR_LIGHTER, COLOR_PRIMARY, cssvarTypography } from 'design-tokens'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    text-rendering: optimizelegibility;
  }

  html, body {
    ${cssvarTypography('paragraph')}
    background-color: ${COLOR_LIGHTER};
    color: ${COLOR_DARKER};
  }

  a {
    color: ${COLOR_PRIMARY};
    text-decoration: none;
    cursor: pointer;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  textarea {
    font-family: inherit;
  }
`
