import { assertNever } from 'functions'
import { css } from 'styled-components'
import { media } from './media'

type Align = 'center' | 'flex-end' | 'flex-start' | 'baseline' | 'stretch'

type Basis = string

type Direction = 'column' | 'column-reverse' | 'row' | 'row-reverse'

type Grow = number

type Shrink = number

type Justify = 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between'

type Wrap = 'nowrap' | 'wrap'

/** @deprecated */
export type Flexbox = {
  align: Align
  basis: Basis
  direction: Direction
  grow: Grow
  justify: Justify
  shrink: Shrink
  wrap: Wrap
}

type MdFlexbox = {
  mdAlign: Align
  mdBasis: Basis
  mdDirection: Direction
  mdGrow: Grow
  mdJustify: Justify
  mdShrink: Shrink
  mdWrap: Wrap
}

type LgFlexbox = {
  lgAlign: Align
  lgBasis: Basis
  lgDirection: Direction
  lgGrow: Grow
  lgJustify: Justify
  lgShrink: Shrink
  lgWrap: Wrap
}

/** @deprecated Use the `cdk` one */
export type FlexboxProps = Partial<Flexbox & MdFlexbox & LgFlexbox>

const switchProp = (prop: keyof Flexbox, value: Flexbox[keyof Flexbox]) => {
  switch (prop) {
    case 'align':
      return `align-items: ${value};`
    case 'justify':
      return `justify-content: ${value};`
    case 'basis':
    case 'direction':
    case 'grow':
    case 'shrink':
    case 'wrap':
      return `flex-${prop}: ${value};`
    default:
      return assertNever(prop)
  }
}

const getPropStyle = <P extends keyof Flexbox>(prop: P, value?: Flexbox[P]) => {
  if (value === undefined) {
    return ''
  }

  // NOTE: The reason why we call a separate function instead of directly having the switch
  // statement is that the type of `prop` is not narrowed when using `P extends Keys`.
  return switchProp(prop, value)
}

/**
 * Enhances a styled component with flexbox-related props.
 * Note: among the supported props there are `direction` and `wrap`, which `styled-components` forwards
 * to the base element. These unwanted props can be removed starting from `styled-component@5.1.0`
 * using the `shouldForwardProp` API on the component that is using `flexbox`.
 */
/** @deprecated Use the `cdk` one */
export const flexbox = css<FlexboxProps>`
  display: flex;
  min-width: 0;
  min-height: 0;

  ${({ align, basis, direction, grow, justify, shrink, wrap }) =>
    `
      ${getPropStyle('align', align)}
      ${getPropStyle('basis', basis)}
      ${getPropStyle('direction', direction)}
      ${getPropStyle('grow', grow)}
      ${getPropStyle('justify', justify)}
      ${getPropStyle('shrink', shrink)}
      ${getPropStyle('wrap', wrap)}
    `}

  ${({ mdAlign, mdBasis, mdDirection, mdGrow, mdJustify, mdShrink, mdWrap }) =>
    media.medium`
      ${getPropStyle('align', mdAlign)}
      ${getPropStyle('basis', mdBasis)}
      ${getPropStyle('direction', mdDirection)}
      ${getPropStyle('grow', mdGrow)}
      ${getPropStyle('justify', mdJustify)}
      ${getPropStyle('shrink', mdShrink)}
      ${getPropStyle('wrap', mdWrap)}
    `}

  ${({ lgAlign, lgBasis, lgDirection, lgGrow, lgJustify, lgShrink, lgWrap }) =>
    media.large`
      ${getPropStyle('align', lgAlign)}
      ${getPropStyle('basis', lgBasis)}
      ${getPropStyle('direction', lgDirection)}
      ${getPropStyle('grow', lgGrow)}
      ${getPropStyle('justify', lgJustify)}
      ${getPropStyle('shrink', lgShrink)}
      ${getPropStyle('wrap', lgWrap)}
    `}
`
