import { fromZonedTime, getTimezoneOffset } from 'date-fns-tz'
import { addMilliseconds, parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { FormatDateEnum } from './format'
import { formatWithTimeZone } from './formatWithTimeZone'

export const shiftUtcByTimezoneDaylightSavingTime = (timeZone: string, referDate: Date | number) => (date: Date) =>
  fromZonedTime(
    pipe(
      date,
      formatWithTimeZone(FormatDateEnum.ATOM, timeZone, referDate),
      parseISO,
      addMilliseconds(getTimezoneOffset(timeZone, referDate)),
    ),
    timeZone,
  )
