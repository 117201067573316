import { Flex } from '../../Flex'
import { Text } from '../../Text'
import { CalendarCell } from './CalendarCell'

const weekDays = [
  { id: 'uh37whueofxt0kq5', value: 'L' },
  { id: 'taqevt2i2afz6lbq', value: 'M' },
  { id: '5y3fusc8qxetri58', value: 'M' },
  { id: 'zx6yzc0am0luu1wa', value: 'G' },
  { id: '64zj4tdeqrg906rc', value: 'V' },
  { id: '3l6b9dela4jpc4mm', value: 'S' },
  { id: '555cyz8nm95swjkb', value: 'D' },
]

/** @deprecated */
export function CalendarHeader() {
  return (
    <Flex direction="row">
      {weekDays.map(({ id, value }) => (
        <CalendarCell key={id}>
          <Flex align="center" p={8}>
            <Text colorName="green10" kind="label">
              {value}
            </Text>
          </Flex>
        </CalendarCell>
      ))}
    </Flex>
  )
}
