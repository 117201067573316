/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { ElevationName } from '../types/elevation'
import { cssvar, cssvarName, cssvarValue } from '../utils/cssvar'

export const DESIGN_TOKENS_ELEVATION_PREFIX = 'elevation'

export const cssvarNameElevation = (elevation: ElevationName) =>
  cssvarName(`${DESIGN_TOKENS_ELEVATION_PREFIX}-${elevation}`)

export const cssvarValueElevation = (elevation: ElevationName) =>
  cssvarValue(`${DESIGN_TOKENS_ELEVATION_PREFIX}-${elevation}`)

export const cssvarElevation = (elevation: ElevationName) => cssvar(`${DESIGN_TOKENS_ELEVATION_PREFIX}-${elevation}`)
