import { setDefaultOptions } from 'date-fns'
import { hoursToMinutes } from 'date-fns/fp'
import { FormatDateLanguage, fromFormatDateLanguageToDateFnsLocale } from 'dates'
import { pipe } from 'fp-ts/function'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { getEnv } from '~/utils/getEnv'

export type Language = FormatDateLanguage

export const allowedLanguages: Record<string, Language> = {
  en: 'en',
  it: 'it',
}

const defaultLanguage: Language = 'it'

export type LanguageProviderContext = {
  language: Language
  setLanguage: (language: Language) => void
}

export const LanguageContext = createContext<LanguageProviderContext | null>(null)

function isAllowedLanguage(input: string): input is Language {
  return Object.values(allowedLanguages).includes(input as Language)
}

type Props = {
  children: ReactNode
}

const languageDetectorRegex = /^([a-z]{2})(?:[-_]).*/i

const languageDetector = new LanguageDetector(null, {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage'],
  lookupQuerystring: 'lng',
  lookupCookie: 'snrs-lng',
  lookupLocalStorage: 'snrs-lng',
  lookupSessionStorage: 'snrs-lng',
  caches: ['localStorage', 'cookie', 'sessionStorage'],
  cookieMinutes: pipe(24, hoursToMinutes),
  cookieDomain: getEnv('LANGUAGE_COOKIE_DOMAIN'),
  cookieOptions: { path: '/', sameSite: 'strict' },
  convertDetectedLanguage: (value) => value.replace(languageDetectorRegex, (_, language) => language.toLowerCase()),
})

export const LanguageProvider = ({ children }: Props) => {
  const [language, setLanguage] = useState<Language | null>(null)

  const changeLanguage = useCallback((value: Language) => {
    setLanguage(value)

    languageDetector.cacheUserLanguage(value)
    setDefaultOptions({ locale: fromFormatDateLanguageToDateFnsLocale[value] })
    document.documentElement.lang = value
  }, [])

  useEffect(() => {
    const run = async () => {
      const detectedLanguages = languageDetector.detect()

      const detectedLanguage = Array.isArray(detectedLanguages) ? detectedLanguages.at(0) : detectedLanguages

      if (!detectedLanguage) {
        changeLanguage(defaultLanguage)

        return
      }

      changeLanguage(isAllowedLanguage(detectedLanguage) ? detectedLanguage : defaultLanguage)
    }

    run().catch(() => {
      changeLanguage(defaultLanguage)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!language) {
    return <CenteredLoader />
  }

  return (
    <LanguageContext.Provider value={{ language, setLanguage: changeLanguage }}>{children}</LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const state = useContext(LanguageContext)

  if (!state) {
    throw new Error('The `useLanguage` should be wrapped with `LanguageProvider`.')
  }

  return state
}
