import { range } from 'arrays'
import { getDaysInMonth } from 'date-fns'
import { addDays, setHours, startOfMonth } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { Day } from '../types'

/** @deprecated */
export const getCurrentMonthDays = (date: Date): Pick<Day, 'belongsToCurrentMonth' | 'date'>[] =>
  pipe(date, getDaysInMonth, (number) =>
    range(number, (index) => ({
      belongsToCurrentMonth: true,
      date: pipe(date, startOfMonth, addDays(index), setHours(12)),
    })),
  )
