import { ColorName, colors } from '@serenis-health/tokens'
import { noop } from 'functions'
import { Xmark } from 'iconoir-react'
import { useCallback, useEffect } from 'react'
import { Flex } from './Flex'
import { Pressable } from './Pressable'
import { Text } from './Text'

type Props = {
  children: string
  id: string
  onClick?: (id: Props['id']) => void
  onClose?: (id: Props['id']) => void
  removeToast: (id: Props['id']) => void
  timeout?: number
  type: 'alert' | 'info' | 'success' | 'warning'
}

const backgrounds: Record<Props['type'], ColorName> = {
  alert: 'coral02',
  info: 'purple02',
  success: 'green02',
  warning: 'yellow02',
}

const borders: Record<Props['type'], ColorName> = {
  alert: 'coral04',
  info: 'purple04',
  success: 'green04',
  warning: 'yellow04',
}

const fills: Record<Props['type'], ColorName> = {
  alert: 'coral10',
  info: 'purple08',
  success: 'green10',
  warning: 'yellow10',
}

/** @deprecated */
export const Toast = ({ children, id, onClick, onClose = noop, removeToast, timeout, type }: Props) => {
  const handleClick = useCallback(() => {
    if (!onClick) {
      removeToast(id)

      return
    }

    onClick(id)
  }, [id, onClick, removeToast])

  const handleClose = useCallback(() => {
    onClose(id)

    removeToast(id)
  }, [id, onClose, removeToast])

  useEffect(() => {
    if (!timeout) {
      return
    }

    const timeoutId = setTimeout(handleClose, timeout)

    return () => {
      clearTimeout(timeoutId)
    }
  })

  return (
    <Flex
      background={backgrounds[type]}
      borderColor={borders[type]}
      borderRadius={8}
      borderSize={1}
      direction="row"
      justify="space-between"
      p={16}
    >
      <Flex grow={1} pl={8} pr={16} pt={2} shrink={1}>
        <Pressable align="flex-start" grow={1} onClick={handleClick} shrink={1}>
          <Text colorName={fills[type]} kind="paragraph" newLine>
            {children}
          </Text>
        </Pressable>
      </Flex>

      <Flex>
        <Pressable onClick={handleClose}>
          <Xmark color={colors[fills[type]]} height={24} width={24} />
        </Pressable>
      </Flex>
    </Flex>
  )
}
