import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisChatSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        clipRule="evenodd"
        d="M4.202.96C5.807.846 8.359.73 12 .73s6.193.117 7.798.23c1.607.111 2.883 1.323 3.026 2.947.123 1.394.242 3.499.242 6.413 0 2.915-.12 5.02-.242 6.414-.143 1.623-1.42 2.835-3.026 2.947-1.54.108-3.952.22-7.36.229l-3.572 3.062c-.798.683-2.03.117-2.03-.934V19.82a84.018 84.018 0 0 1-2.634-.139c-1.607-.112-2.883-1.324-3.026-2.947-.123-1.394-.242-3.5-.242-6.414 0-2.914.12-5.019.242-6.413.143-1.624 1.42-2.836 3.026-2.948Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
)
