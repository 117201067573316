import { Flex, MaxWidth640px } from 'cdk'
import { Text } from 'ui'
import { ContactUsButton } from '~/components/ContactUsButton'
import { LocationReloadButton } from '~/components/LocationReloadButton'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ErrorPage } from './ErrorPage'

export const GenericErrorContent = () => (
  <MaxWidth640px>
    <Text fontWeight="600" kind="h3" textAlign="center">
      <Translation id="generic.errorOccurred.title" />
    </Text>

    <Flex $pt={48}>
      <TranslationMarkdown id="generic.errorOccurred.text" kind="paragraph" pt={12} />
    </Flex>

    <Flex $mdDirection="row" $mdJustify="space-between" $pt={80} $wrap="wrap">
      <Flex $p={8}>
        <LocationReloadButton />
      </Flex>

      <Flex $p={8}>
        <ContactUsButton />
      </Flex>
    </Flex>
  </MaxWidth640px>
)

export const GenericError = () => (
  <ErrorPage>
    <GenericErrorContent />
  </ErrorPage>
)
