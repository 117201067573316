import styled from 'styled-components'

/** @deprecated */
export const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
`

HiddenInput.displayName = 'HiddenInput'
