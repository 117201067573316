import styled from 'styled-components'
import { media } from '../utils/media'
import { Flex, FlexProps } from './Flex'

/** @deprecated Import `cdk` one */
export const Sticky = styled(Flex)<FlexProps>`
  ${media.medium`
    height: 100%;
    position: sticky;
    top: 0px;
  `}
`

Sticky.displayName = 'Sticky'
