import { useCallback, useMemo, useSyncExternalStore } from 'react'

export function useMediaQuery(query: string) {
  const matchMedia = useMemo(() => {
    if (typeof window === 'undefined') {
      return undefined
    }

    return window.matchMedia(query)
  }, [query])

  const subscribe = useCallback(
    (callback: () => void) => {
      matchMedia?.addEventListener('change', callback)

      return () => {
        matchMedia?.removeEventListener('change', callback)
      }
    },
    [matchMedia],
  )

  const getSnapshot = useCallback(() => matchMedia?.matches, [matchMedia])

  const getServerSnapshot = useCallback(() => true, [])

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)
}
