import { COLOR_YELLOW_40, COLOR_YELLOW_60 } from 'design-tokens'
import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const ReportsGood = ({ width }: Props) => (
  <svg
    data-test-id="icon-reports-good"
    fill="none"
    version="1.1"
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M15 9H9v7H3v5h12V9Z" fill={COLOR_YELLOW_60} fillRule="evenodd" />
    <path
      d="M9 21h6m-6 0v-5m0 5H3.6a.6.6 0 0 1-.6-.6v-3.8c0-.33.27-.6.6-.6H9m6 5V9m0 12h5.4a.6.6 0 0 0 .6-.6V3.6a.6.6 0 0 0-.6-.6h-4.8a.6.6 0 0 0-.6.6V9m0 0H9.6a.6.6 0 0 0-.6.6V16"
      stroke={COLOR_YELLOW_40}
      strokeWidth="1.5"
    />
  </svg>
)
