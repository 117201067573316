import { FormatDateLanguage } from 'dates'
import { CalendarDays } from './components/CalendarDays'
import { CalendarHeader } from './components/CalendarHeader'
import { CalendarNavigation } from './components/CalendarNavigation'
import { CalendarPaper } from './components/CalendarPaper'
import { Day } from './types'

type Props = {
  language: FormatDateLanguage
  onNext: () => void
  onPrevious: () => void
  onSelect: (day: Day) => void
}

/** @deprecated */
export function View({ language, onNext, onPrevious, onSelect }: Props) {
  return (
    <CalendarPaper>
      <CalendarNavigation language={language} onNext={onNext} onPrevious={onPrevious} />

      <CalendarHeader />

      <CalendarDays onSelect={onSelect} />
    </CalendarPaper>
  )
}
