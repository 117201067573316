import { formatDurationWithOptions } from 'date-fns/fp'
import { FormatDateLanguage, fromFormatDateLanguageToDateFnsLocale } from './format'

type Format = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'

export const formatDuration = (format: Format[], locale?: FormatDateLanguage) =>
  formatDurationWithOptions({
    format,
    locale: locale && fromFormatDateLanguageToDateFnsLocale[locale],
  })
