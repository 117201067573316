import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisTherapy = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 14.85h5.326c.918 0 1.76-.03 2.302-.054a1.605 1.605 0 0 0 1.082-.465 11.805 11.805 0 0 0 1.895-2.532 1.558 1.558 0 0 0 0-1.498 11.805 11.805 0 0 0-1.895-2.532 1.605 1.605 0 0 0-1.082-.465 52.507 52.507 0 0 0-2.302-.054H12M12 9.625H6.674c-.918 0-1.759-.029-2.302-.053a1.605 1.605 0 0 1-1.082-.465 11.803 11.803 0 0 1-1.895-2.532 1.558 1.558 0 0 1 0-1.499A11.803 11.803 0 0 1 3.29 2.544a1.605 1.605 0 0 1 1.082-.465 52.47 52.47 0 0 1 2.302-.054H12M7.725 21.976h8.55M12 2.035V22"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
