import { identity } from 'fp-ts/function'
import { PagePath } from '~/routes/types'

// NOTE: this preserves only the one that still uses hidden sub-paths
// It should not be needed in the long run
export type Route =
  | PagePath
  | `/chat/${string}`
  | `/chat/${string}/clinical-tests`
  | `/chat/${string}/clinical-tests/${string}`
  | `/chat/${string}/detail`
  | `/chat/${string}/diagnosis`
  | `/chat/${string}/diagnosis/edit`
  | `/chat/${string}/diary`
  | `/chat/${string}/diary/${string}`
  | `/chat/${string}/detail/therapy-session/${string}/schedule`
  | `/chat/${string}/food-journal`
  | `/detail/therapy-session/${string}/schedule`
  | `/patients/${string}`
  | `/patients/${string}/clinical-tests`
  | `/patients/${string}/clinical-tests/${string}`
  | `/patients/${string}/diagnosis`
  | `/patients/${string}/diagnosis/edit`
  | `/patients/${string}/diary`
  | `/patients/${string}/food-journal`
  | `/patients/${string}/therapy-sessions`
  | `/patients/${string}/therapy-session/${string}/schedule`
  | `/patients/${string}/journaling`

export const getRoute = identity<Route>
