import { colors } from '@serenis-health/tokens'
import { TextAreaStyleProps } from '../../types'

/** @deprecated */
export const getPlaceholderColorFromProps = ({ invalid }: Pick<TextAreaStyleProps, 'invalid'>) => {
  if (invalid) {
    return colors.coral10
  }

  return colors.grey08
}
