import { NavArrowDown } from 'iconoir-react'
import styled from 'styled-components'
import { Flex } from '../../Flex'

const ArrowWrapper = styled(Flex)`
  position: absolute;
  top: 50%;
  right: 0;
  pointer-events: none;
  transform: translateY(-8px);
`

/** @deprecated */
export const Arrow = () => (
  <ArrowWrapper>
    <NavArrowDown color="black" height={16} width={16} />
  </ArrowWrapper>
)
