import { ColorName, colors } from '@serenis-health/tokens'
import { css } from 'styled-components'

/** @deprecated Use the `cdk` one */
export type BackgroundProps = {
  background?: ColorName
  backgroundHover?: ColorName
}

/** @deprecated Use the `cdk` one */
export const background = css<BackgroundProps>`
  ${({ background: backgroundColorName }) => backgroundColorName && `background-color: ${colors[backgroundColorName]};`}
  ${({ backgroundHover: backgroundHoverColorName }) =>
    backgroundHoverColorName && `&:active, &:hover { background-color: ${colors[backgroundHoverColorName]}; }`}
`
