import { startOfMonth } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { toInt } from 'numbers'

/** @deprecated */
export const getFirstDateOfWeekOfMonth = (date: Date): number => {
  const firstDay = pipe(date, startOfMonth, format(FormatDateEnum.DAY_OF_WEEK), toInt, (num) => num - 1)

  if (firstDay < 0) {
    return firstDay + 7
  }

  return firstDay
}
