import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisJournaling = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.893 22.303h11.495a5.776 5.776 0 0 0 3.546-1.216l.361-.281m0 0 1.534-1.193a2.263 2.263 0 0 0-1.39-4.048h-.288a2.263 2.263 0 0 0-1.389 4.048l1.534 1.193Zm0 0 1.144.889c.507.394 1.13.608 1.773.608h1.897"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M18.837 8.347c.817-.817 1.055-2.046.382-2.985a14.265 14.265 0 0 0-1.507-1.766 14.26 14.26 0 0 0-1.766-1.507c-.939-.673-2.168-.435-2.985.382L2.483 12.95a1.708 1.708 0 0 0-.508 1.052c-.07.783-.146 2.3.006 4.46a.934.934 0 0 0 .865.866c2.16.152 3.677.076 4.46.006.402-.036.768-.224 1.053-.508L18.837 8.347Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M8.04 19.08a1.669 1.669 0 0 0-.026-.068c-.24-.605-.868-1.84-2.373-3.345-1.506-1.505-2.74-2.133-3.345-2.374a1.606 1.606 0 0 0-.069-.025c-.14.22-.229.47-.252.733-.07.783-.146 2.3.006 4.46a.934.934 0 0 0 .865.866c2.16.152 3.677.076 4.46.006.265-.024.513-.113.734-.253Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M16.721 9.966s-.39-1.455-2.158-3.223c-1.768-1.768-3.223-2.158-3.223-2.158"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
