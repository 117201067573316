import { specialCharsRegex, specialToNormalChars } from './constants/specialChars'

/**
 * Snakify the given string.
 *
 * @param string  Text to snake
 */
export const snake = (text: string): string =>
  text
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(specialCharsRegex, (value) => specialToNormalChars[value])
    .replace(/&/g, '_and_')
    .replace(/[^\w_]+/g, '_')
    .replace(/__+/g, '_')
    .replace(/^_+/, '')
    .replace(/_+$/, '')
