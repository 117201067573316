import { BORDER_RADIUS_XS, ColorName, SPACING_XS } from 'design-tokens'
import { AriaAttributes, PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { uidWithPrefix } from 'uid'
import { Card } from './Card'
import { Text } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

type Kind = 'error' | 'warning'

export const alertKindsColors: Record<
  Kind,
  {
    background: ColorName
  }
> = {
  error: { background: 'red-20' },
  warning: { background: 'yellow-20' },
} as const

type AlertContainerProps = PropsWithChildren<{
  kind: Kind
}>

const AlertContainer = styled(Card)
  .withConfig({ displayName: 'Alert', shouldForwardProp })
  .attrs<AlertContainerProps>(({ kind }) => ({
    backgroundColorName: alertKindsColors[kind].background,
    size: 'xs',
  }))<AlertContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: ${SPACING_XS};
  overflow: hidden;
  border-color: transparent;
  border-radius: ${BORDER_RADIUS_XS};
`

const EndChildrenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export type AlertProps =
  | {
      children?: never
      description: string
      endChildren: PropsWithChildren['children']
      kind: Kind
      startChildren: PropsWithChildren['children']
      title?: string
    }
  | PropsWithChildren<{
      description?: never
      endChildren: PropsWithChildren['children']
      kind: Kind
      startChildren: PropsWithChildren['children']
      title?: never
    }>

const AlertChildrenContainer = styled.div`
  flex: 1;
`

export const Alert = ({ children, description, endChildren, kind, startChildren, title }: AlertProps) => {
  const uuid = useMemo(() => uidWithPrefix('alert'), [])

  const ariaAttrs: AriaAttributes = useMemo(
    () => ({
      'aria-describedby': `description_${uuid}`,
      ...(title && { 'aria-labelledby': `title_${uuid}` }),
    }),
    [title, uuid],
  )

  return (
    <AlertContainer {...(!children && ariaAttrs)} as="div" kind={kind} role="alert">
      {startChildren}
      <AlertChildrenContainer>
        {children ? (
          <div>{children}</div>
        ) : (
          <>
            {title && (
              <Text as="p" fontWeight="600" id={`title_${uuid}`} kind="caption">
                {title}
              </Text>
            )}
            <Text as="p" id={`description_${uuid}`} kind="caption">
              {description}
            </Text>
          </>
        )}
      </AlertChildrenContainer>
      <EndChildrenContainer>{endChildren}</EndChildrenContainer>
    </AlertContainer>
  )
}
