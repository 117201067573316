import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

type Context = {
  incrementUnreadCount: (count: number) => void
  messagesVisible: boolean
  resetUnreadCount: () => void
  setMessagesVisible: (visible: boolean) => void
  unreadCount: number
}

const IntercomStateContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const IntercomStateProvider = ({ children }: Props) => {
  const [messagesVisible, setMessagesVisible] = useState(false)
  const [unreadCount, setUnreadCount] = useState<number>(0)

  const incrementUnreadCount = useCallback((newMessages: number) => {
    setUnreadCount((current) => current + newMessages)
  }, [])

  const resetUnreadCount = useCallback(() => {
    setUnreadCount(0)
  }, [])

  return (
    <IntercomStateContext.Provider
      value={{
        messagesVisible,
        resetUnreadCount,
        setMessagesVisible,
        incrementUnreadCount,
        unreadCount,
      }}
    >
      {children}
    </IntercomStateContext.Provider>
  )
}

export const useIntercomState = () => {
  const state = useContext(IntercomStateContext)

  if (!state) {
    throw new Error('The `useIntercomState` should be wrapped with `IntercomStateProvider`.')
  }

  return state
}
