import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathCouples = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-couples"
    fill="none"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M30.48 16.67a13.8 13.8 0 0 1-19.66 12.51 13.8 13.8 0 0 1 18.36-18.36c.83 1.78 1.3 3.76 1.3 5.85Zm-3.99-9.7A16.67 16.67 0 0 0 6.96 26.5 13.8 13.8 0 1 1 26.5 6.96Zm4.82 1.72A16.66 16.66 0 1 0 8.69 31.31 16.66 16.66 0 1 0 31.31 8.69Zm1.73 4.82A16.67 16.67 0 0 1 13.5 33.04 13.8 13.8 0 1 0 33.04 13.5Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
