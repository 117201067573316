import { ColorName, cssvarColor } from 'design-tokens'
import styled, { keyframes } from 'styled-components'

const rotate1 = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const rotate2 = keyframes`
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }

  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }

  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
`

const Spinner = styled.svg.withConfig({ displayName: 'Loader' })`
  transform-origin: center;
  animation: ${rotate1} 2s linear infinite;

  circle {
    animation: ${rotate2} 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
`

export type LoaderProps = {
  colorName?: ColorName
  size?: number
}

export const Loader = ({ colorName, size }: LoaderProps) => (
  <Spinner
    aria-atomic="true"
    aria-busy="true"
    aria-live="polite"
    height={size ?? '1em'}
    role="status"
    stroke={colorName ? cssvarColor(colorName) : 'currentColor'}
    viewBox="0 0 24 24"
    width={size ?? '1em'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>loading</title>
    <circle cx="12" cy="12" fill="none" r="9.5" strokeWidth="2" />
  </Spinner>
)
