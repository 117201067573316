import { Flex, FlexProps, PositionAbsolute, PositionRelative } from 'cdk'
import { COLOR_ERROR, SPACING_2XS, SPACING_3XS, SPACING_LG, SPACING_SM, SPACING_XS, TIME_300 } from 'design-tokens'
import {
  ChangeEvent,
  CSSProperties,
  FormEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { Hint } from './Hint'
import { Label } from './Label'
import { defaultTextInputSize, TextInput } from './TextInput'

type SizeName = 'sm' | 'md' | 'lg'

type LabelPositionStyle = {
  top: FlexProps['$top']
}

export const labelPositionsStyles: Record<SizeName, LabelPositionStyle> = {
  sm: { top: `calc(${SPACING_2XS} * -1)` },
  md: { top: SPACING_3XS },
  lg: { top: SPACING_XS },
}

type InputSizesStyle = {
  paddingBlock: CSSProperties['paddingBlock']
}

export const inputSizesStyles: Record<SizeName, InputSizesStyle> = {
  sm: { paddingBlock: `${SPACING_SM} ${SPACING_3XS}` },
  md: { paddingBlock: `20px ${SPACING_3XS}` },
  lg: { paddingBlock: `${SPACING_LG} ${SPACING_XS}` },
}

type TextFieldContainer = {
  $sizeName: SizeName
}

const TextFieldContainer = styled(PositionRelative)<TextFieldContainer>(
  ({ $sizeName }) => css`
    &:has(${Label}) input {
      padding-block: ${({}) => inputSizesStyles[$sizeName].paddingBlock};
    }

    &:has(:user-invalid) {
      ${Label} {
        color: ${COLOR_ERROR};
      }
    }
  `,
)

const LabelContainer = styled(Flex)`
  transition: background-color ${TIME_300} ease-out;
`

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  hint?: ReactNode
  sizeName?: SizeName
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ children, hint, sizeName = defaultTextInputSize, onChange, onInvalid, ...inputProps }, ref) => {
    const [errorMessage, setErrorMessage] = useState('')
    const identifier = `${inputProps.name}-${inputProps.value}`
    const hintId = hint ? `${identifier}-hint` : undefined
    const errorMessageId = `${identifier}-error`
    const labelId = `${identifier}-label`

    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(event.target.validationMessage)

        if (onChange) {
          onChange(event)
        }
      },
      [onChange],
    )

    const handleOnInvalid = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        setErrorMessage(event.currentTarget.validationMessage)

        if (onInvalid) {
          onInvalid(event)
        }
      },
      [onInvalid],
    )

    return (
      <TextFieldContainer $gap={SPACING_3XS} $sizeName={sizeName}>
        {children && (
          <PositionAbsolute
            $align="flex-start"
            $left={SPACING_XS}
            $right={SPACING_XS}
            $top={labelPositionsStyles[sizeName].top}
            as={Label}
            colorName={inputProps.disabled || inputProps.readOnly ? 'neutral-80' : 'primary'}
            forwardedAs="label"
            htmlFor={identifier}
            id={labelId}
          >
            <LabelContainer $backgroundColorName={inputProps.disabled ? 'neutral-20' : 'lighter'} $px={SPACING_3XS}>
              {children}
            </LabelContainer>
          </PositionAbsolute>
        )}
        <TextInput
          ref={ref}
          $sizeName={sizeName}
          aria-describedby={errorMessage ? errorMessageId : hintId}
          aria-labelledby={labelId}
          id={identifier}
          {...inputProps}
          onChange={handleOnChange}
          onInvalid={handleOnInvalid}
        />
        {!inputProps.disabled && (errorMessage || hint) && (
          <Flex $px={SPACING_SM}>
            {errorMessage ? (
              <Hint colorName="error" id={errorMessageId}>
                {errorMessage}
              </Hint>
            ) : (
              <Hint id={hintId}>{hint}</Hint>
            )}
          </Flex>
        )}
      </TextFieldContainer>
    )
  },
)
