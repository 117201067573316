import { colors, typographyFlavours } from '@serenis-health/tokens'
import styled from 'styled-components'
import { shouldForwardProp } from '../../../utils/shouldForwardProp'
import { SelectStyleProps } from '../types'
import { getBackgroundColorFromProps } from '../utils/getBackgroundColorFromProps'
import { getBorderColorFromProps } from '../utils/getBorderColorFromProps'
import { getColorFromProps } from '../utils/getColorFromProps'

/** @deprecated */
export const SelectField = styled.select.withConfig({
  displayName: 'SelectField',
  shouldForwardProp,
})<SelectStyleProps>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 46px;
  padding: 8px 16px 8px 0;
  border: none;
  border-bottom: 1px solid ${getBorderColorFromProps(false)};
  background-color: ${getBackgroundColorFromProps};
  color: ${getColorFromProps};
  box-shadow: inset 0 0 0 2px ${colors.transparent};
  outline: none;
  font-size: ${typographyFlavours.paragraph.fontSize};
  line-height: ${typographyFlavours.paragraph.lineHeight};
  opacity: 1;
  -webkit-text-fill-color: ${getColorFromProps};
  appearance: none;
`
