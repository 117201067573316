/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `10px` */
export const FONT_SIZE_10 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-10)` as const
export const FONT_SIZE_10_VALUE = '10px' as const

/** `12px` */
export const FONT_SIZE_12 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-12)` as const
export const FONT_SIZE_12_VALUE = '12px' as const

/** `14px` */
export const FONT_SIZE_14 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-14)` as const
export const FONT_SIZE_14_VALUE = '14px' as const

/** `16px` */
export const FONT_SIZE_16 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-16)` as const
export const FONT_SIZE_16_VALUE = '16px' as const

/** `18px` */
export const FONT_SIZE_18 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-18)` as const
export const FONT_SIZE_18_VALUE = '18px' as const

/** `20px` */
export const FONT_SIZE_20 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-20)` as const
export const FONT_SIZE_20_VALUE = '20px' as const

/** `24px` */
export const FONT_SIZE_24 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-24)` as const
export const FONT_SIZE_24_VALUE = '24px' as const

/** `32px` */
export const FONT_SIZE_32 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-32)` as const
export const FONT_SIZE_32_VALUE = '32px' as const

/** `40px` */
export const FONT_SIZE_40 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-40)` as const
export const FONT_SIZE_40_VALUE = '40px' as const

/** `48px` */
export const FONT_SIZE_48 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-48)` as const
export const FONT_SIZE_48_VALUE = '48px' as const

/** `56px` */
export const FONT_SIZE_56 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-56)` as const
export const FONT_SIZE_56_VALUE = '56px' as const

/** `64px` */
export const FONT_SIZE_64 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-64)` as const
export const FONT_SIZE_64_VALUE = '64px' as const
