import { HTMLAttributeAnchorTarget, ReactChild } from 'react'
import styled from 'styled-components'
import { ButtonProps } from '../types/button'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { Button } from './Button'

type Attrs = {
  disabled: boolean
}

const Anchor = styled.a.withConfig({
  displayName: 'Anchor',
  shouldForwardProp,
})<Attrs>`
  &:disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
`

type Props = {
  children: ReactChild
  href: string
  rel?: string
  role?: string
  target?: HTMLAttributeAnchorTarget
} & Exclude<ButtonProps, 'as' | 'children' | 'onClick' | 'role' | 'type'>

/** @deprecated Import `Button` with `Link` from `ui` */
export const LinkButton = ({ children, disabled, href, rel, role, target, ...props }: Props) => (
  <Anchor disabled={Boolean(disabled)} href={href} rel={rel} role={role} target={target}>
    <Button {...props} as="span" disabled={disabled}>
      {children}
    </Button>
  </Anchor>
)

LinkButton.displayName = 'LinkButton'
