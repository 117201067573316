import { ColorName } from '@serenis-health/tokens'
import { ReactNode, useMemo } from 'react'
import { Flex } from './Flex'
import { Text } from './Text'

type Props = {
  children: ReactNode
  kind?: 'alert' | 'info' | 'info-light' | 'success' | 'warning'
}

type MemoValue = {
  backgroundColorName: ColorName
  mainColorName: ColorName
}

/** @deprecated Import `ui` one */
export const SuggestionBox = ({ children, kind = 'info' }: Props) => {
  const { backgroundColorName, mainColorName } = useMemo((): MemoValue => {
    if (kind === 'warning') {
      return {
        backgroundColorName: 'yellow02',
        mainColorName: 'yellow08',
      }
    }

    if (kind === 'info-light') {
      return {
        backgroundColorName: 'purple02',
        mainColorName: 'purple08',
      }
    }

    if (kind === 'info') {
      return {
        backgroundColorName: 'purple04',
        mainColorName: 'purple08',
      }
    }

    if (kind === 'success') {
      return {
        backgroundColorName: 'green02',
        mainColorName: 'green08',
      }
    }

    if (kind === 'alert') {
      return {
        backgroundColorName: 'coral02',
        mainColorName: 'coral08',
      }
    }

    return {
      backgroundColorName: 'purple04',
      mainColorName: 'purple08',
    }
  }, [kind])

  return (
    <Flex background={backgroundColorName} borderRadius={8} direction="row">
      <Flex background={mainColorName} basis="6px" />
      <Flex grow={1} p={16} shrink={1}>
        <Text colorName={mainColorName} kind="label">
          {children}
        </Text>
      </Flex>
    </Flex>
  )
}
