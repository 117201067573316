import { useCallback } from 'react'
import { useInitializeDataLayer } from './useInitializeDataLayer'

type Payload = Window['dataLayer'][number]

const dataLayerSet = new Set()

export const useDataLayer = () => {
  useInitializeDataLayer()

  const add = useCallback((payload: Payload, uniqueId?: string) => {
    if (uniqueId && dataLayerSet.has(uniqueId)) {
      return
    }

    if (uniqueId) {
      dataLayerSet.add(uniqueId)
    }

    window.dataLayer.push(payload)
  }, [])

  return { add }
}
