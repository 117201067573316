import styled from 'styled-components'
import { Flex } from './Flex'

export const PositionAbsolute = styled(Flex)
  .withConfig({ displayName: 'PositionAbsolute' })
  .attrs({ $position: 'absolute' })``
export const PositionFixed = styled(Flex).withConfig({ displayName: 'PositionFixed' }).attrs({ $position: 'fixed' })``
export const PositionRelative = styled(Flex)
  .withConfig({ displayName: 'PositionRelative' })
  .attrs({ $position: 'relative' })``
export const PositionSticky = styled(Flex)
  .withConfig({ displayName: 'PositionSticky' })
  .attrs({ $position: 'sticky' })``

export const MaxWidth320px = styled(Flex).withConfig({ displayName: 'MaxWidth320px' }).attrs({ $maxWidth: 320 })``
export const MaxWidth400px = styled(Flex).withConfig({ displayName: 'MaxWidth400px' }).attrs({ $maxWidth: 400 })``
export const MaxWidth640px = styled(Flex).withConfig({ displayName: 'MaxWidth640px' }).attrs({ $maxWidth: 640 })``
export const MaxWidth840px = styled(Flex).withConfig({ displayName: 'MaxWidth840px' }).attrs({ $maxWidth: 840 })``
export const MaxWidth1024px = styled(Flex).withConfig({ displayName: 'MaxWidth1024px' }).attrs({ $maxWidth: 1024 })``
export const MaxWidth1260px = styled(Flex).withConfig({ displayName: 'MaxWidth1260px' }).attrs({ $maxWidth: 1260 })``

export const OverflowAuto = styled(Flex)
  .withConfig({ displayName: 'OverflowAuto' })
  .attrs((props) => ({ $pb: 1, $grow: 1, $overflow: 'auto', $shrink: 1, ...props }))``
export const OverflowHidden = styled(Flex)
  .withConfig({ displayName: 'OverflowHidden' })
  .attrs({ $overflow: 'hidden' })``

export const Form = styled(Flex).withConfig({ displayName: 'Form' }).attrs({ as: 'form', noValidate: true })``
