import { cssvarElevation, ElevationName } from 'design-tokens'
import { css } from 'styled-components'
import { StyledProps } from '../utils/props'

type BaseElevationProps = {
  elevationName?: ElevationName
}

export type ElevationProps = StyledProps<BaseElevationProps>

export const generateElevationStyles = ({ elevationName }: BaseElevationProps) => css`
  box-shadow: ${elevationName && cssvarElevation(elevationName)};
`
