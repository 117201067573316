import {
  BORDER_RADIUS_XS,
  COLOR_ACCENT_10,
  COLOR_BLUE_20,
  COLOR_DARKER,
  COLOR_GREEN_20,
  COLOR_GREY_20,
  COLOR_PRIMARY_10,
  COLOR_RED_20,
  COLOR_YELLOW_20,
  SPACING_3XS,
  SPACING_XS,
  cssvarValueColor,
} from 'design-tokens'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Text, TextProps } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

type Kind = 'accent' | 'black' | 'grey' | 'info' | 'primary' | 'sexology' | 'success' | 'warning' | 'error'

export const chipKindsColors: Record<
  Kind,
  {
    background: ReturnType<typeof cssvarValueColor>
    text: Exclude<TextProps['colorName'], undefined>
  }
> = {
  accent: { background: COLOR_ACCENT_10, text: 'accent-80' },
  black: { background: COLOR_DARKER, text: 'lighter' },
  grey: { background: COLOR_GREY_20, text: 'grey-80' },
  info: { background: COLOR_BLUE_20, text: 'blue-80' },
  primary: { background: COLOR_PRIMARY_10, text: 'primary-80' },
  sexology: { background: '#F95A6D', text: 'lighter' },
  success: { background: COLOR_GREEN_20, text: 'green-80' },
  warning: { background: COLOR_YELLOW_20, text: 'yellow-80' },
  error: { background: COLOR_RED_20, text: 'red-80' },
} as const

type ChipTextProps = Omit<TextProps, 'kind'> & { chipKind: Kind }

const ChipText = styled(Text).withConfig({ displayName: 'Chip', shouldForwardProp }).attrs({
  kind: 'caption',
  fontWeight: '500',
})<ChipTextProps>`
  display: inline-flex;
  padding: ${SPACING_3XS} ${SPACING_XS};
  border-radius: ${BORDER_RADIUS_XS};
  background-color: ${({ chipKind }) => chipKindsColors[chipKind].background};
`

export type ChipProps = PropsWithChildren<{ kind: Kind }>

export const Chip = ({ children, kind }: ChipProps) => (
  <ChipText chipKind={kind} colorName={chipKindsColors[kind].text} ellipsis>
    {children}
  </ChipText>
)
