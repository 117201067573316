import styled from 'styled-components'
import { Flex, FlexProps } from './Flex'

type Props = Omit<
  FlexProps,
  | 'borderRadius'
  | 'borderRadiusBottomLeft'
  | 'borderRadiusBottomRight'
  | 'borderRadiusTopLeft'
  | 'borderRadiusTopRight'
  | 'mdBorderRadius'
  | 'mdBorderRadiusBottomLeft'
  | 'mdBorderRadiusBottomRight'
  | 'mdBorderRadiusTopLeft'
  | 'mdBorderRadiusTopRight'
  | 'lgBorderRadius'
  | 'lgBorderRadiusBottomLeft'
  | 'lgBorderRadiusBottomRight'
  | 'lgBorderRadiusTopLeft'
  | 'lgBorderRadiusTopRight'
>

type ExternalProps = {
  boxShadow?: boolean
}

const defaultProps: Partial<FlexProps & ExternalProps> = {
  background: 'white',
  borderColor: 'purple06',
  borderSize: 1,
  boxShadow: false,
  p: 24,
}

const fixedProps: Pick<FlexProps, 'borderRadius'> = { borderRadius: 16 }

/** @deprecated Use Card from `ui` */
export const CardBox = styled(Flex).attrs<Props & ExternalProps>((props) => ({
  ...defaultProps,
  ...props,
  ...fixedProps,
}))<Props & ExternalProps>`
  ${({ boxShadow }) => boxShadow && 'box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);'}
`

CardBox.displayName = 'CardBox'
