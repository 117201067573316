import styled from 'styled-components'

/** @deprecated */
export const ListItem = styled.li`
  padding-top: 16px;

  &:first-child {
    padding-top: 0;
  }
`

ListItem.displayName = 'ListItem'
