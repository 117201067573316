import { MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisMemberGetMember = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.735 19.588c-.11 1.148-1.002 2-2.152 2.095-1.524.128-4.01.267-7.608.267-3.599 0-6.085-.14-7.608-.267-1.15-.096-2.041-.947-2.152-2.095C2.104 18.436 2 16.724 2 14.35c0-2.374.104-4.087.215-5.238.11-1.148 1.002-2 2.152-2.095 1.524-.128 4.01-.267 7.608-.267 3.599 0 6.085.14 7.608.267 1.15.096 2.041.947 2.152 2.095.111 1.151.215 2.864.215 5.238 0 2.374-.104 4.087-.215 5.238ZM9.975 6.75v15.2M13.975 6.75v15.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M11.975 6.75c0-1.425-1.9-4.75-4.75-4.75-1.662 0-2.85 1.276-2.85 2.85 0 .799.329 1.521.859 2.039M11.975 6.75c0-1.425 1.9-4.75 4.75-4.75 1.662 0 2.85 1.276 2.85 2.85a2.842 2.842 0 0 1-.859 2.039"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
