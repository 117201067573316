import { cssvarValueMediaQuery, MediaQueryName } from 'design-tokens'
import { useMediaQuery } from 'hooks'
import { memo, PropsWithChildren } from 'react'

export type MountOnProps = PropsWithChildren<{
  mediaQuery: MediaQueryName
}>

export const MountOn = memo(({ mediaQuery, children }: MountOnProps) => {
  const isInMediaQuery = useMediaQuery(cssvarValueMediaQuery(mediaQuery))

  if (!isInMediaQuery) {
    return null
  }

  return children
})
