import styled, { CSSProperties } from 'styled-components'

export type SpaceProps = {
  $whiteSpace?: CSSProperties['whiteSpace']
}

const Span = styled.span<SpaceProps>`
  white-space: ${({ $whiteSpace }) => $whiteSpace};
`

export const Space = ({ $whiteSpace = 'pre' }: SpaceProps) => (
  <Span $whiteSpace={$whiteSpace} role="separator">
    {' '}
  </Span>
)
