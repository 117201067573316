import styled from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { Flex } from './Flex'

type Props = {
  value: string
}

const MaxWidth = styled(Flex).withConfig({
  displayName: 'MaxWidth',
  shouldForwardProp,
})<Props>`
  width: 100%;
  max-width: ${({ value }) => value};
  margin: 0 auto;
`

/** @deprecated Use the `cdk` one */
export const MaxWidth320px = styled(MaxWidth).attrs<Props>(() => ({
  value: '320px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

/** @deprecated Use the `cdk` one */
export const MaxWidth400px = styled(MaxWidth).attrs<Props>(() => ({
  value: '400px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

/** @deprecated Use the `cdk` one */
export const MaxWidth640px = styled(MaxWidth).attrs<Props>(() => ({
  value: '640px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

/** @deprecated Use the `cdk` one */
export const MaxWidth840px = styled(MaxWidth).attrs<Props>(() => ({
  value: '840px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

/** @deprecated Use the `cdk` one */
export const MaxWidth1024px = styled(MaxWidth).attrs<Props>(() => ({
  value: '1024px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

/** @deprecated Use the `cdk` one */
export const MaxWidth1260px = styled(MaxWidth).attrs<Props>(() => ({
  value: '1260px',
}))<Partial<Props>>`
  /* stylelint-disable-next-line no-empty-source */
`

MaxWidth320px.displayName = 'MaxWidth320px'
MaxWidth400px.displayName = 'MaxWidth400px'
MaxWidth640px.displayName = 'MaxWidth640px'
MaxWidth840px.displayName = 'MaxWidth840px'
MaxWidth1024px.displayName = 'MaxWidth1024px'
MaxWidth1260px.displayName = 'MaxWidth1260px'
