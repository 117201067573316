import { BORDER_WIDTH_0, BORDER_WIDTH_1, ColorName, SPACING_0, cssvarColor } from 'design-tokens'
import styled, { Interpolation, css } from 'styled-components'
import { shouldForwardProp } from './utils/shouldForwardProp'

type Direction = 'horizontal' | 'vertical'

export type DividerProps = {
  colorName?: ColorName
  direction?: Direction
}

export const dividerStyles: Record<Direction, Interpolation<object>> = {
  horizontal: css`
    border-bottom-width: ${BORDER_WIDTH_1};
  `,
  vertical: css`
    height: 100%;
    border-right-width: ${BORDER_WIDTH_1};
  `,
}

export const Divider = styled.hr.withConfig({
  displayName: 'Divider',
  shouldForwardProp,
})<DividerProps>`
  margin: ${SPACING_0};
  border: ${BORDER_WIDTH_0} solid ${({ colorName = 'primary-40' }) => cssvarColor(colorName)};
  ${({ direction = 'horizontal' }) => dividerStyles[direction]}
`
