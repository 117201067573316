import { formatInTimeZone } from 'date-fns-tz'
import { FormatDateEnum, FormatDateLanguage, fromFormatDateLanguageToDateFnsLocale } from './format'

export const formatWithTimeZone =
  (pattern: FormatDateEnum, timeZone = 'Europe/Rome', originalDate?: Date | number, locale?: FormatDateLanguage) =>
  (date: Date | number) =>
    formatInTimeZone(date, timeZone, pattern, {
      locale: locale && fromFormatDateLanguageToDateFnsLocale[locale],
      originalDate,
      weekStartsOn: 1,
      timeZone,
    })
