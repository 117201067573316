import styled from 'styled-components'
import { Flex } from './Flex'

/** @deprecated Use the `cdk` one */
export const Form = styled(Flex).attrs(() => ({
  as: 'form',
  noValidate: true,
}))`
  /* stylelint-disable-next-line no-empty-source */
`

Form.displayName = 'Form'
