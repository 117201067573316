import { ColorName } from '@serenis-health/tokens'
import { State } from '../types/useInput'

type Param = {
  focused: boolean
  state: State
  value?: string
}

/** @deprecated */
export const getBorderColorName = ({ focused, state, value }: Param): ColorName => {
  if (state === 'disabled') {
    return 'grey04'
  }

  if (state === 'read-only') {
    return 'purple06'
  }

  if (state === 'error') {
    return 'coral10'
  }

  if (state === 'warning') {
    return 'yellow08'
  }

  if (focused) {
    return 'purple08'
  }

  if (value) {
    return 'purple04'
  }

  return 'grey04'
}
