import styled from 'styled-components'
import { shouldForwardProp } from 'ui'
import { UnorderedList } from 'ui-deprecated'

export const OrderedList = styled(UnorderedList)
  .withConfig({
    displayName: 'OrderedList',
    shouldForwardProp,
  })
  .attrs({ as: 'ol' })`
  padding-left: 16px;
  list-style-type: auto;
`
