export const SerenisLogoIcon = () => (
  <svg fill="none" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.093 18.491c4.237 1.066 2.611 2.958 2.398 3.17-2.424 2.266-10.151 4.53-16.12 6.102C.613 25.314 0 21.556 0 15.988 0 3.197 3.197 0 15.987 0c3.064 0 5.595.187 7.647.64 1.305.293 2.45.692 3.41 1.252.24.133.453.266.666.4a85.121 85.121 0 0 0-6.954 1.758c-3.41 1.04-7.194 2.478-10.551 4.396-2.025 1.146-4.956 3.65-3.704 6.129 1.013 1.811 4.397 2.557 5.063 2.744l4.53 1.172Z"
      fill="#7A59F5"
    />
    <path
      d="M12.55 12.07c.053 1.279 2.025 1.892 3.677 2.345 2.104.612 3.89 1.119 5.968 1.811 1.545.533 3.517 1.52 3.597 3.65.106 3.544-6.475 6.928-8.127 7.808-1.705.905-6.874 3.41-7.993 3.916 1.785.293 3.89.4 6.341.4C28.803 32 32 28.803 32 16.013c0-5.275-.533-8.899-2.078-11.377-2.318.373-17.559 3.65-17.372 7.434Z"
      fill="#7A59F5"
    />
  </svg>
)
